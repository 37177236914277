import { Button, Form, Input, Modal, notification, Select, DatePicker, Tooltip, Popover, InputNumber, Checkbox, Tag } from "antd";
import moment from "moment";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ValidationCustom } from "../../../commons/validationCustom";
import * as Actions from "../../../libs/actions";
import * as CommonService from "../../../services/commonService";
import * as EnterInfo from "../../../services/enterInfoService";
import * as PatientService from "../../../services/patientService";
import * as PrintService from "../../../services/printService";
import * as RegisterService from "../../../services/registerService";
import * as TuTrucService from "../../../services/tutrucservice";
import * as ChiDinhService from "../../../services/chiDinhService";
import * as ConfigService from "../../../services/configService";
import * as PhongKhamService from "../../../services/PhongKhamService";
import * as PackageService from "../../../services/packageService";
import Administrative from "../components/administrative";
import Autocomplete from "../../../components/autocomplete";
import EditModal from "./editModal";
import ModalCLS from "../../../components/MDCLS";
import ModalPackage from "../../../components/MDCLS/modalPackage";
import Registerpatient from "./registedPatients";
import Upload from "./upload";
import viVN from "antd/es/date-picker/locale/vi_VN";
import * as InvoiceService from "../../../services/invoiceService";
import AutocompleteTable from "../../../components/autocompletetable";
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import * as PromotionalCampagin from "../../promotion/PromotionServices";
import * as NhanVienSerivce from '../../../services/employeeService';
import PhieuKhaoSat from "./survey";
import SelectRoom from "../../../components/MDCLS/selectRoom";
import SelectRoomMulti from "../../../components/MDCLS/selectRoomMulti";
import * as CorporationServices from '../../corporation/CorporationServices';
import AppliedVoucher from '../../invoice/payment/appliedVoucher';
import { KHAMBENH, LOYALTY_TYPE, FEATURE, BUTTON } from "../../../configureHIS/common/constants";
import { FeatureDeactive } from '../../../configure/featureConfig';
import { ContinuousLegend } from "@antv/g2/lib/dependents";
import { connect } from 'react-redux';
import { groupBy, isNullOrEmpty, renumber, uppercaseFirstLetter } from '../../../libs/util';
import Handlebars from "handlebars";
import handlebarHelper from '../../../libs/handlebarHelper';
import {default as num2words} from 'vn-num2words';

const { Option } = Select;
const { RangePicker } = DatePicker;
class index extends Component {
	constructor(props) {
		super(props);
		this.validator = new SimpleReactValidator(ValidationCustom);
		this.state = {
			lstSubject: [],
			lstdepartment: [],
			lstPatient: [],
			lstColums: [],
			hiddenRelativeClass: "relative-close",
			code: "",
			isBHYT: false,
			routeName: "",
			canPrint: false,
			maql: 0,
			route: false,
			showBHYT: false,
			managementID: "",
			dateIn: "",
			visiblePatient: false,
			lstChiDinhBenhNhan: [],
			patientID: null,
			visibleCLS: false,
			listSelectedServices: [],
			listSelectedPackage: [],
			isnotEdit: false,
			total: 0,
			patientHistoryInfo: [],
			department: "",
			reasonCome: "",
			visiblePackage: false,
			listServices: [],
			selectedExam: null,
			enterNumber: "",
			lstRooms: [],
			selectedRoom: null,
			visibleListRegisted: true,
			madoituong: 2,
			cardNumber: "",
			dateExpired: [null, null],
			lstcardAddress: [],
			cardCode: "",
			cardAddress: "",
			cardAddressLabel: "",
			typeBHYT: 1,
			activeRelative: false,
			activeBHYT: true,
			activeUpload: true,
			activeTab: 0,
			relative: "",
			relativeName: "",
			relativeAddress: "",
			relativePhone: "",
			fileList: [],
			// samplecode: null,
			serKey: "",
			vsbPopover: false,
			listServiceVoucher: [],
			banggia: "default",
			visibleKS: false,
			phone: "",
			email: "",
			cccd: "",
			detailPatient: {},
			mach: null,
			huyetap: null,
			chieucao: null,
			cannang: null,
			nhietdo: null,
			nhiptho: null,
			selectedDetail: {},
			listCoporators: [],
            coporator: null,
			idsale: '',
			saleName : "",
			disableCopor : false,
			defaultpayment : null,
			ghichupromo:null,
			visiblePromotion : false,
            lstMarketing : [],
            marketing : [],
		};
	}

	componentWillMount() {
		this.loadListPatient();
		this.LoadDepartment();
		this.loadCoporation();
		ConfigService.BangGiaDefaultSite()
			.then((respone) => {
				if (respone.isSuccess) {
					this.setState(
						{
							banggia: respone.item ? respone.item : "default",
							banggiaTheoSite: respone.item ? respone.item : "default",
						},
						() => { }
					);
				}
			})
			.catch((err) => { });
		this.loadMarketing();
	}
	componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
			ConfigService.BangGiaDefaultSite()
				.then((respone) => {
					if (respone.isSuccess) {
						this.setState(
							{
								banggia: respone.item ? respone.item : "default",
								banggiaTheoSite: respone.item ? respone.item : "default",
							},
							() => { }
						);
					}
				})
				.catch((err) => { });
        }
	}

	resetState() {
		Actions.ResetBarCode(true);
		this.administrative.resetState();
		this.setState({
			hiddenRelativeClass: "relative-close",
			showBHYT: false,
			routeName: "",
			maql: 0,
			canPrint: false,
			route: false,
			patientID: null,
			listSelectedServices: [],
			isnotEdit: false,
			code: "",
			//visibleListRegisted: false,
			department: "",
			reasonCome: "",
			patientHistoryInfo: [],
			listSelectedPackage: [],
			selectedExam: null,
			selectedRoom: null,
			enterNumber: "",
			madoituong: 2,
			cardNumber: "",
			dateExpired: [null, null],
			cardCode: "",
			cardAddress: "",
			cardAddressLabel: "",
			typeBHYT: 1,
			relative: "",
			relativeName: null,
			relativeAddress: "",
			relativePhone: "",
			fileList: [],
			phone: null,
			// samplecode: null,
			voucher: "",
			chieucao: null,
			cannang: null,
			huyetap: null,
			nhietdo: null,
			mach: null,
			nhiptho : null,
			disableCopor : false,
            coporator: null,
			banggia: this.state.banggiaTheoSite,//"default",
			// banggiaTheoSite: "default",
			idngt : '',
			idsale : '',
			tenngt: '',
			saleName: '',
			defaultpayment : null,
			marketing: [],
		});
		this.formBHYT.setFieldsValue({
			cardNumber: "",
			dateExpired: [null, null],
			cardCode: "",
			cardAddress: "",
			cardAddressLabel: "",
			typeBHYT: 1,
			relative: "",
			relativeName: "",
			relativeAddress: "",
			relativePhone: "",
		});
		this.form.setFieldsValue({
			coporator : null,
		});
		this.validator.hideMessages();
		if (this.modalCLS) this.modalCLS.reset();
		if (this.modalPackage) this.modalPackage.reset();
		if (this.modalCapthuoc) this.modalCapthuoc.handleChangeNewMedicine([]);
		
	}

	componentDidMount() {
		//this.resetState();
		this.getExampleService();
		this.DanhSachPKBenh();
	}

	DanhSachPKBenh() {
		ChiDinhService.DanhSachPKBenh().then((respone) => {
			if (respone.isSuccess) {
				this.setState({
					lstRooms: respone.data,
				});
			}
			this.getExampleService();
		});
	}

	getExampleService() {
		ChiDinhService.getExamService()
			.then((result) => {
				if (result.isSuccess) {
					let data = result.data;
					for (let item of data) {
						item.label = item.name;
						item.value = item.id;
					}
					this.setState({
						listServices: data,
					});
				}
				Actions.setLoading(false);
			})
			.catch((err) => {
				Actions.setLoading(false);
				Actions.openMessageDialog("Error", err);
			});
	}
	LoadDepartment() {
		CommonService.DanhSachKhoaKham().then((result) => {
			if (result.isSuccess) {
				this.setState({
					lstdepartment: result.data,
				});
			}
		});
	}

	loadKCB(key) {
		EnterInfo.GetDSKCB(key)
			.then((result) => {
				if (result.isSuccess && result.data.length > 0) {
					this.setState({
						lstcardAddress: result.data,
					});
				}
			})
			.catch((err) => {
				notification.warning({ message: "Tải dữ liệu KCB bị lỗi" });
			});
	}

	searchDetailRegister() {
		const data = new FormData();
		data.append("ngayvaovien", moment(this.state.dateIn).format("YYYY-MM-DD"));
		data.append("maql", this.state.managementID);
		RegisterService.DetailRegister(data)
			.then((result) => {
				if (result.isSuccess) {
					this.setState({ patientObject: result.item });
				} else {
					notification.error({ message: result.err.msgString });
				}
			})
			.catch((err) => {
				notification.error({ message: "Lấy thông tin đối tượng bị lỗi" });
			});
	}

	loadCoporation(key = "", page = 1, size = 10) {
        CorporationServices.GetList(key, page, size).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listCoporators: res.data
                })
            }
        })
    }

	loadCoporationDetail(id){
		CorporationServices.CoporationDetail(id).then(result => {
			if (result.isSuccess) {
				if (result.item.banggia !== this.state.banggia) {
					this.setState({ listSelectedServices : []});
				}
				if (result.tennguoigioithieu !== '') {
					this.setState({
					idngt : result.item.nguoigioithieu,
					tenngt : result.item.tennguoigioithieu,
					disabled : true
				})
				}
				if (result.item.banggia && result.item.banggia !== ''){
					this.setState({
						banggia : result.item.banggia,
					})
					if (result.item.banggia != this.state.banggia) {
						this.modalCLS && this.modalCLS.ChangeBangGia(result.item.banggia);
						this.listServicesRef.handleSearch(this.searchIndication());
					}
				} else {
					this.setState({
						banggia : this.state.banggiaTheoSite,
					})
					if (result.item.banggia != this.state.banggia) {
						this.listServicesRef.handleSearch(this.searchIndication());
					}
				} 
				if (result.item.idsale !== '' && result.item.idsale !== 0){
					this.setState({
						idsale : result.item.sale,
						saleName : result.item.saleName
					})
				}
				if (result.item.paymenttype !== '' && result.item.paymenttype !== 0 && result.item.paymenttype !== undefined){
					this.setState({
						defaultpayment : result.item.paymenttype
					})
				} else {
					this.setState({
						defaultpayment : null
					})
				}
				//this.setState({ disableCopor: true})
			}
		})
		
	}

	loadNguoiGioiThieu(data) {
        if (this.props.onType) {
            this.props.onType(data);
        }
        this.setState({ key: data });
        return PatientService.GetListNguoiGioiThieu(data, false, 1, 20);
    }
    SelectNguoiGioiThieu(data) {
        this.setState({ idngt: data.id, tenngt: data.hoten });
    }

	loadSale(data) {
        if (this.props.onType) {
            this.props.onType(data);
        }
        this.setState({ key: data });
        return NhanVienSerivce.DanhSachNhanVien(data, 1, 100,'26');
    }
    SelectSale(data) {
        this.setState({ idsale: data.id, saleName: data.hoten });
    }

	loadListPatient(key = "", page = 1, size = 10) {
		CommonService.GetListPatient(key, page, size)
			.then((result) => {
				if (result.isSuccess) {
					const columns = [
						{
							title: "Mã BN",
							dataIndex: "patientID",
							key: "patientID",
						},
						{
							title: "Mã quản lý",
							dataIndex: "managementID",
							key: "managementID",
						},
						{
							title: "Họ tên",
							dataIndex: "patientName",
							key: "patientName",
							render: (value) => {
								return <b>{value}</b>;
							},
						},
						{
							title: "Ngày sinh",
							dataIndex: "birthYear",
							key: "birthYear",
						},
						{
							title: "Ngày vào khám",
							dataIndex: "dateIn",
							key: "dateIn",
						},
						{
							title: "cmnd",
							dataIndex: "cmnd",
							key: "cmnd",
							render: (text, record, index) => {
								return <span>{text.toString() === "0" ? "" : text}</span>;
							},
						},
						{
							render: (row) => {
								return (
									<button
										onClick={() => {
											this.setState(
												{
													managementID: row.managementID,
													dateIn: row.dateIn,
													visiblePatient: true,
												},
												() => {
													this.searchDetailRegister(
														this.state.managementID,
														this.state.dateIn
													);
												}
											);
										}}
										className="btn btn-outline-warning"
										title="Chỉnh sửa"
									>
										<i className="fa fa-pencil-square-o"></i>
									</button>
								);
							},
						},
					];
					let dataSource = result.data.map(function (e, index) {
						let o = Object.assign({}, e);
						o.key = index;
						return o;
					});
					this.setState({
						lstColums: columns,
						lstPatient: dataSource,
						managementID: result.data.managementID,
						dateIn: result.data.dateIn,
						totalRecords: result.totalRecords,
					});
				}
			})
			.catch((err) => {
				notification.error({ message: err });
			});
	}

	getBHYT(value) {
		if (value.length !== 20) {
			this.setState({
				isBHYT: false,
				cardAddress: "",
				cardAddressLabel: "",
			});
			return;
		}
		EnterInfo.GetDSKCB(
			value.substring(value.length - 6, value.length - 1)
		).then((result) => {
			if (result.isSuccess && result.data.length > 0) {
				let lstcardAddress = this.state.lstcardAddress;
				if (
					lstcardAddress.filter((e) => e.value === result.data[0].value)
						.length === 0
				) {
					lstcardAddress.push({
						label: result.data[0].label,
						value: result.data[0].value,
					});
				}

				this.setState({
					lstcardAddress,
					isBHYT: true,
					cardAddress: result.data[0].value,
					cardAddressLabel: result.data[0].label,
					routeName: result.data[0].value,
				});
			} else {
				this.setState({
					isBHYT: false,
					cardAddress: "",
					cardAddressLabel: "",
				});
			}
		});
	}

	getListChiDinh(data) {
		this.setState({ lstChiDinhBenhNhan: data });
	}

	async createNew() {
		let numberError = 0;
		if (this.props.marketingRequired && this.state.marketing.length == 0) {
			Actions.openMessageDialog("Thông báo", "Vui lòng chọn kênh khách hàng");
			return;
		}
		if (this.props.coporationRequired && isNullOrEmpty(this.state.coporator)) {
			Actions.openMessageDialog("Thông báo", "Vui lòng chọn nguồn khách hàng");
			return;
		}
		if (
			this.state.selectedExam !== undefined &&
			this.state.selectedRoom === undefined
		) {
			notification.warning({ message: "vui lòng chọn phòng khám" });
			return;
		}

		await this.administrative
			.getForm()
			.validateFields()
			.then((values) => {
				if (values.errorFields) numberError += values.errorFields.length;
			})
			.catch((ex) => {
				if (ex.errorFields) numberError += ex.errorFields.length;
			});

		if (this.state.madoituong === 1) {
			if (
				this.state.cardNumber === "" ||
				this.state.dateExpired[1] === null ||
				this.state.cardAddress === ""
			) {
				notification.warning({ message: "Dữ liệu BHYT chưa được nhập" });
			}
		}

		let department = "";
		let mavpkham = "";
		let maphongkham = "";

		if (this.state.selectedExam) {
			mavpkham = this.state.selectedExam.toString();

			for (let item of this.state.lstRooms) {
				if (item.value.toString() === this.state.selectedRoom.toString()) {
					department = item.departmentID;
					maphongkham = item.roomCode;
					break;
				}
			}
		}

		if (this.administrative.validator.allValid() && this.validator.allValid()) {
			if (numberError === 0) {
				Actions.setLoading(true);

				let { listSelectedServices, listSelectedPackage } = this.state;
				let lstSelect = listSelectedServices.concat(listSelectedPackage);
				for (let item of lstSelect) {
					item.idChiDinh = "0";
					// item.ten = item.name;
					item.madoituong = "2";
					// item.mavp = item.id.toString();
					// if (item.type === 3) {
					//     item.mavp = item.idvp.toString();
					// }

					item.giaaTH = item.dongia.toString();
					item.dongia = item.dongia;
					item.soluong = item.soluong;
					item.ngay = moment().format("YYYY-MM-DD HH:mm");

					for (let item of this.state.lstdepartment) {
						if (item.mavp == item.mavp) {
							item.department = item.makp;
							break;
						}
					}
				}

				let dataAdministrative = this.administrative.getState();
				const data = {
					barCode: dataAdministrative.code ? dataAdministrative.code : "",
					code: dataAdministrative.code ? dataAdministrative.code : "",
					name: dataAdministrative.name.toUpperCase(),
					dob: moment(dataAdministrative.dob).format('YYYY-MM-DD'),
					age: dataAdministrative.age,
					gender: dataAdministrative.gender,
					people: "25",
					cmnd: dataAdministrative.cmnd,
					phone: dataAdministrative.phone,
					email: dataAdministrative.email,
					address: dataAdministrative.address,
					city: dataAdministrative.city
						? dataAdministrative.city.toString()
						: "",
					district: dataAdministrative.district
						? dataAdministrative.district.toString()
						: "",
					ward: dataAdministrative.ward
						? dataAdministrative.ward.toString()
						: "",
					jobAddress: dataAdministrative.jobAddress,
					village:
						dataAdministrative.village !== undefined
							? dataAdministrative.village
							: "",
					country: dataAdministrative.nation,
					dateInHospital: moment().format("YYYY/MM/DD"),
					timeInHospital: moment().format("HH:mm"),
					department: department,
					subjectCode: this.state.madoituong.toString(),
					lstChiDinh: lstSelect,
					relative: this.state.relative,
					relativeName: this.state.relativeName,
					relativeAddress: this.state.relativeAddress,
					relativePhone: this.state.relativePhone,
					mach: this.state.mach,
					nhietdo: this.state.nhietdo,
					huyetap: this.state.huyetap,
					cannang: this.state.cannang,
					chieucao: this.state.chieucao,
					nhiptho : this.state.nhiptho,
					reasonCome: this.state.reasonCome,
					mavpkham: mavpkham,
					maphongkham: maphongkham,
					cardNumber: this.state.cardNumber,
					cardFrom:
						this.state.madoituong === 1
							? moment(this.state.dateExpired[0]).format("YYYY-MM-DD")
							: "0001-01-01",
					cardTo:
						this.state.madoituong === 1
							? moment(this.state.dateExpired[1]).format("YYYY-MM-DD")
							: "0001-01-01",
					cardCode: this.state.cardCode,
					cardType: this.state.typeBHYT.toString(),
					idkhaibao: dataAdministrative.idkhaibao,
					// 'samplecode': this.state.listSelectedServices.length > 0 ? this.state.samplecode.toString() : null
                    coporator: dataAdministrative.coporator && dataAdministrative.coporator !== "" ? dataAdministrative.coporator : null,
					sendby: dataAdministrative.sendby,
					onlyyear: dataAdministrative.onlyyear,
					idsale: this.state.idsale ? this.state.idsale.toString() : '',
					otCopor: this.state.coporator,
					manguoigioithieu: this.state.idngt ? this.state.idngt.toString() : "",
					// marketing : dataAdministrative.marketing ? dataAdministrative.marketing : '', // for btdbn
					marketing : this.state.marketing ? this.state.marketing : '', // for tiepdon
				};
				RegisterService.SavePatient(data)
					.then((result) => {
						if (result.isSuccess) {
							notification.success({ message: "Lưu thành công" });
							this.administrative.setMabn(result.patientID);
							this.Registerpatient.loadListPatient();
							this.setState(
								{
									canPrint: true,
									maql: result.id,
									isnotEdit: true,
									enterNumber: result.enterNumber,
									code: result.patientID,
								},
								() => {
									if (this.state.fileList.length > 0) {
										Actions.setLoading(true);
										let formdata = new FormData();
										let listFile = this.state.fileList;
										listFile.forEach((element) => {
											formdata.append("listFile", element.originFileObj);
										});
										PatientService.UploadFile(formdata, result.patientID)
											.then((x) => {
												notification.success({
													message: "Upload file thành công!",
												});
												this.setState({ isReload: !this.state.isReload });
												Actions.setLoading(false);
											})
											.catch((err) => {
												Actions.setLoading(false);
											});
									}
								}
							);
							// this.payConfirm({ mabn: result.patientID, ngay: moment(), maql: result.id });
						} else {
							Actions.openMessageDialog(!isNullOrEmpty(result.err.msgCode) ? result.err.msgCode : "Thông báo", result.err.msgString);
						}
						Actions.setLoading(false);
					})
					.catch((err) => {
						Actions.setLoading(false);
					});
			} else {
				notification.error({ message: "Vui lòng kiểm tra lại dữ liệu!" });
			}
		} else {
			notification.error({ message: "Vui lòng kiểm tra lại dữ liệu!" });
			this.administrative.validator.showMessages();
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	PrintRecepRecipt(maql) {
		if (maql === 0) {
			Actions.openMessageDialog("Lỗi", "Chưa lưu đăng ký bệnh nhân!");
			return;
		}
		PrintService.printReceptionReceipt(maql)
        .then((result) => {
            if (isNullOrEmpty(result.item.template)) {
                Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc các dịch vụ đã được thực hiện, lấy mẫu...");
                return;
            } else {
                // const listTestGroup = groupBy(result.item.data.listTest.listIndication, "tenloaiXN");
                // const resultGroupArr = Object.keys(listTestGroup).map((key) => ({ key, key, value: listTestGroup[key] }));
                // const resultGroupArrRenumber = renumber(resultGroupArr);
                // result.item.data.listTest.listIndication = resultGroupArrRenumber;

				let templateMaster = "";
				var finalData = result.item.data;
				const listOrderId = result.item.listOrderId; // Danh sách orderId
				var listTestGroupByOrderId = groupBy(result.item.data.listTest.listIndication, "orderid"); // Danh sách chỉ định xét nghiệm đã phân theo orderid
				Object.keys(listTestGroupByOrderId).forEach(orderId => {
					let listTestBelongToGroup = listTestGroupByOrderId[orderId];
					const listTestGroup = groupBy(listTestBelongToGroup, "tenloaiXN");
					const resultGroupArr = Object.keys(listTestGroup).map((key) => ({ key, value: listTestGroup[key] }));
					const resultGroupArrRenumber = renumber(resultGroupArr);
					let totalMoney = listTestBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
					let dataInfo = {
						listIndication: resultGroupArrRenumber,
						sidBarcode: listOrderId?.find(x => x.label == orderId)?.value,
						title: `${finalData.listTest.title?.toUpperCase() ?? "PHIẾU ĐĂNG KÝ XÉT NGHIỆM"}`,
						amount: totalMoney,
						amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
					};
					let keyName = `test${orderId}`;
					var tempData = {...finalData, [`${keyName}`]: dataInfo};
					finalData = tempData;
					templateMaster += result.item.subTemplate.replaceAll("data.listTest", `data.${keyName}`); // Thêm template chỉ định xét nghiệm phân theo orderId và theo loại
				});
				const template = Handlebars.compile(result.item.template.replace("#listDangKyXetNghiem", templateMaster));
				result.item.data = finalData;
                Handlebars.registerHelper(handlebarHelper);
                Actions.DoPrint(template(result.item));
            }
        })
	}
	loadDoiTuong() {
		TuTrucService.DanhSachDoiTuong()
			.then((result) => {
				if (result.isSuccess) {
					this.setState({
						lstSubject: result.data,
					});
				}
			})
			.catch((err) => {
				notification.warning({ message: "Tải dữ liệu đối tượng bị lỗi" });
			});
	}

	getListHistory(patientID) {
		PatientService.getListHistory(patientID)
			.then((respone) => {
				if (respone.isSuccess === true) {
					if (respone.item && respone.item.trans)
						this.setState({ patientHistoryInfo: respone.item.trans });
				} else {
					Actions.openMessageDialog(
						"Error " + respone.err.msgCode,
						respone.err.msgString.toString()
					);
				}
			})
			.catch((ex) => {
				Actions.openMessageDialog("lỗi", ex);
			});
	}

	payConfirm(info) {
		Actions.setLoading(true);
		let patient = {};
		let services = {
			ngay: info.ngay.format("YYYY/MM/DD"),
			maql: info.maql,
			hinhthucthanhtoan: 1, //Thanh toán tiền mặt
			loaithanhtoan: 1,
			tientamung: 0,
			discount: 0,
			dadongtamung: false,
		};
		InvoiceService.SavePayMent((patient = { services }))
			.then((result) => {
				if (result.isSuccess) {
					this.Registerpatient.loadListPatient();
				} else {
					notification.success({ message: "Lỗi khi thanh toán" });
				}
				Actions.setLoading(false);
			})
			.catch((err) => {
				Actions.setLoading(false);
				Actions.openMessageDialog("Lỗi", err);
			});
	}

	notifyUpdate() {
		this.Registerpatient.loadListPatient();
	}

	XoaChiDinhGoiKham(row) {
		let { listSelectedPackage, listSelectedServices } = this.state;
		if (row.packageid) {
			const listResult = listSelectedPackage.filter(
				(x) => x.packageid !== row.packageid
			);
			let total = 0;
			listResult
				.concat(this.state.listSelectedServices)
				.map(
					(x) => (total += (x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
				);
			this.setState({
				listSelectedPackage: listResult,
				total,
			});
		} else {
			let listIdToRemove = [row.id];
			if (row.isParent) {
				listIdToRemove = listIdToRemove.concat(listSelectedServices.filter(x => x.isParent !== undefined).map(x => (x.id)));
			}
			var listResult = listSelectedServices.filter((x) => !listIdToRemove.includes(x.id));
			this.modalCLS && this.modalCLS.XoaChiDinh(row);
			let total = 0;
			listResult
				.concat(this.state.listSelectedPackage)
				.map(
					(x) => (total += (x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
				);
			this.setState({
				listSelectedServices: listResult,
				total,
			});
			if (listIdToRemove.length > 1 && row.isParent)
            	Actions.openMessageDialog("Thông báo", "Đã hủy bỏ các dịch vụ liên quan trong chương trình loyalty");
		}
	}
	onChangeDob = (date) => {
		if (date.isValid() && this.form !== null) {
			let months = new moment().diff(date, "months");

			if (months > 24) {
				this.form.setFieldsValue({
					age: new moment().year() - date.year(),
					agecalbymonth: "",
				});
			} else {
				this.form.setFieldsValue({
					agecalbymonth: months,
					age: new moment().diff(date, "years"),
				});
			}
		}
	};
	searchIndication(value) {
		return ChiDinhService.searchSerivce(0, 0, this.state.banggia, value, 1, 20);
	}

	onSelectIndication(item) {
		const { listSelectedServices, banggia } = this.state;
		var existed = listSelectedServices.find((x) => x.id === item.id);
		if (existed === undefined) {
			PhongKhamService.GetRoomDefault(item.makp, item.id).then((respone) => {
				if (respone.isSuccess) {
					item.phongkham = respone.item.makp || "";
					item.tenphong = respone.item.tenkp || "";
					item.maphong = respone.item.makp || "";
				}
				item.banggia = banggia;
				item.soluong = 1;
				item.idChiDinh = "0";
				let total = 0;
				listSelectedServices.push(item);
				listSelectedServices.map(
					(x) => (total += (x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
				);
				this.setState({
					listSelectedServices: listSelectedServices,
					total: total,
				});
			})
		}
	}

	searchPackage(value) {
		let filter = {
            Keyword : value,
            SkipCount : 0,
            MaxResultCount : 10 
        }
		return PackageService.ListServicePackageAutocomplete(filter);
	}

	onSelectPackage(item) {
		PackageService.ListServicePackageDetail(item.id, this.state.banggia)
			.then((result) => {
				if (result.isSuccess) {
					let packageItem = result.data;
					packageItem.map(x => this.onSelectIndication(x));
				} else {
					notification.warning({ message: result.err.msgString });
				}
			})
			.catch((err) => {
				Actions.openMessageDialog("Lỗi", err); 
			});
	}

	handleEnter(event, nextIndex) {
		if (event.keyCode === 13) {
			try {
				const form = event.target.form;
				const index = Array.prototype.indexOf.call(form, event.target);
				// form.elements[index + 1].focus(); //Focus vào item kế
				form.elements[nextIndex].focus(); //Focus vào item đã chọn
				event.preventDefault();
			} catch (error) { }
		}
	}

	// checkPaylater = (e) => {
    //     if(e.target.checked){
    //        this.setState({ defaultpayment : 4 });
    //     } else {
	// 		this.setState({ defaultpayment : null });
	// 	}
        
    // }

	getCollapseClassName = (name) => {
		let up = "fa fa-chevron-up";
		let down = "fa fa-chevron-down";
		let result = "";
		let expandedAttr = document.getElementById(name);
		if (expandedAttr) {
			var isExpanded = expandedAttr.parentElement.getAttribute('aria-expanded');
			if (isExpanded === "false") {
				result = down;
			} else if (isExpanded === "true") {
				result = up;
			} else {
				if (this.state.activeTab > 0) result = down;
				else result = up;
			}
		} else {
			result = up;
		}
		return result;
	}

	renderPatientInfo(isnotEdit) {
		return (
			<div className="warrperStyleGroup">
				<div className="grouplabel">
					<h4 style={{ fontWeight: "bold", marginBottom: 0 }}>
						Thông tin bệnh nhân
						<Button className="btn btn-color ml-2 btn-sm"
							onClick={() => {
								this.setState({
									detailPatient: this.administrative.getState(),
									visibleKS: true,
								});
							}} >Khảo sát
						</Button>
						<Modal
							maskClosable={false}
							cancelButtonProps={{ style: { display: "none" } }}
							okButtonProps={{ style: { display: "none" } }}
							visible={this.state.visibleKS}
							width={1000}
						><PhieuKhaoSat detailPatient={this.state.detailPatient} onCancel={(visible) => this.setState({ visibleKS: visible })} />
						</Modal>
					</h4>
				</div>
				<div className="grouplabelRight">
					<div style={{ flex: 1, textAlign: "right", marginRight: "40px" }}>
						<strong>Mã bệnh nhân: {this.state.code}</strong>
					</div>
				</div>
				<Administrative
					isNotShowBarCode={true}
					newPatient={true}
					nguoigioithieu={true}
					showDepartment={false}
					disabled={isnotEdit === true}
					ref={(c) => (this.administrative = c)}
					sendData={(patientInfo) => {
						this.setState({
							relative: patientInfo.relative,
							relativeName: patientInfo.relativeName,
							relativeAddress: patientInfo.relativeAddress,
							relativePhone: patientInfo.relativePhone,
							cardCode: patientInfo.cardCode,
							cardNumber: patientInfo.cardNumber,
							dateExpired: [
								moment(patientInfo.cardFrom).year() === 1
									? null
									: moment(patientInfo.cardFrom),
								moment(patientInfo.cardTo).year() === 1
									? null
									: moment(patientInfo.cardTo),
							],
							cardType: Number(patientInfo.cardType),
							cardAddressLabel: patientInfo.cardAddressLabel,
							cardAddress: patientInfo.cardCode,
                            coporator: patientInfo.coporator && patientInfo.coporator !== "" ? patientInfo.coporator : null,
							// tenngt : patientInfo.nguoigioithieu,
							// saleName : patientInfo.saleName,

						});
						if (patientInfo.cardNumber.length >= 10) {
							this.setState({
								madoituong: 1,
								lstcardAddress: [
									{
										value: patientInfo.cardCode,
										label: patientInfo.cardAddressLabel,
									},
								],
							});
						}
                        if (patientInfo.coporator && patientInfo.coporator !== '') {
							this.addNotExistCopr(patientInfo.coporator, patientInfo.nguonkhachhang);
							// this.setState({
							// 	disableCopor : true
							// }) 
							this.loadCoporationDetail(patientInfo.coporator);
							this.form.setFieldsValue({
								coporator: patientInfo.coporator
							})
						} else {
							this.setState({
								disableCopor: false,
								coporator: null,
							})
							this.form.setFieldsValue({
								coporator: null
							})
						}
						this.formBHYT.setFieldsValue({
							cardCode: patientInfo.cardCode,
							cardNumber: patientInfo.cardNumber,
							dateExpired: [
								moment(patientInfo.cardFrom).year() === 1
									? null
									: moment(patientInfo.cardFrom),
								moment(patientInfo.cardTo).year() === 1
									? null
									: moment(patientInfo.cardTo),
							],
						});
					}}
					onSeached={(patientID) => {
						this.setState({
							hiddenRelativeClass: "relative-close",
							showBHYT: false,
							routeName: "",
							maql: 0,
							canPrint: false,
							route: false,
							relative: false,
							patientID: null,
							listSelectedServices: [],
						});
						if (this.modalCLS) this.modalCLS.reset();
						this.setState({ code: patientID, patientID: patientID });
						this.getListHistory(patientID);
					}}
					SetCodeNull={() => {
						this.setState({ code: "", patientID: "" });
					}}
					onReplaceBangGia={(tenBangGia) => {
								if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
									this.setState({
										banggia: tenBangGia,
									});
									this.listServicesRef.handleSearch(this.searchIndication());
								}
							}}
					onReplaceCoporation={(coporator, item) =>{
						if (coporator !== "" && coporator !== null && coporator !== undefined) {
							this.addNotExistCopr(item.value, item.children);
							this.setState({ coporator: coporator });
							this.loadCoporationDetail(coporator);
							this.form.setFieldsValue({
								coporator : coporator
							})
						} else {
							this.setState({
								coporator: null,
								idsale: '',
								idngt: '',
								tenngt : '',
								saleName : '',
								banggia: this.state.banggiaTheoSite,
								disableCopor : false,
								defaultpayment : null,
							})
							this.form.setFieldsValue({
								coporator : null
							})
						}
					}}
				/>

				<div>
					<ul className="nav nav-tabs mt-1" id="myTab" role="tablist">
						<li
							className="nav-item"
							data-toggle="collapse"
							data-target="#collapseRelative"
							onClick={() => {
								if (this.state.activeTab === 1) {
									this.setState({
										activeRelative: !this.state.activeRelative,
									});
								}
								this.setState({ activeTab: 1 });
							}}
						>
							<a
								className="nav-link active"
								id="home-tab"
								data-toggle="tab"
								href="#home"
								role="tab"
								aria-controls="home"
								aria-selected="true"
							>
								Người thân
								<i
									style={{ marginLeft: "5px" }}
									className={this.getCollapseClassName("home-tab")}
								></i>
							</a>
						</li>
						<li
							style={{
								display: `${this.state.madoituong === 1 ? "unset" : "none"}`,
							}}
							className="nav-item"
							data-toggle="collapse"
							data-target="#collapseBHYT"
							onClick={() => {
								if (this.state.activeTab === 2) {
									this.setState({ activeBHYT: !this.state.activeBHYT });
								}
								this.setState({ activeTab: 2 });
							}}
						>
							<a
								className="nav-link"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="false"
							>
								BHYT
								<i
									style={{ marginLeft: "5px" }}
									className={this.getCollapseClassName("profile-tab")}
								></i>
							</a>
						</li>
						<li
							className="nav-item"
							data-toggle="collapse"
							data-target="#uploaddata"
							onClick={() => {
								if (this.state.activeTab === 3) {
									this.setState({ activeUpload: !this.state.activeUpload });
								}
								this.setState({ activeTab: 3 });
							}}
						>
							<a
								className="nav-link"
								id="upload-tab"
								data-toggle="tab"
								href="#upload"
								role="tab"
								aria-controls="profile"
								aria-selected="false"
							>
								Upload hồ sơ
								<i
									style={{ marginLeft: "5px" }}
									className={this.getCollapseClassName("upload-tab")}
								></i>
							</a>
						</li>
					</ul>
					<div className="tab-content" id="myTabContent">
						<div
							className="tab-pane fade show active"
							id="home"
							role="tabpanel"
							aria-labelledby="home-tab"
						>
							<div className={`collapse`} id="collapseRelative">
								<div className="row">
									<div className="col-lg-4 mt-1">
										<label>Họ và tên</label>
										<Form.Item>
											<Input
												value={this.state.relativeName}
												placeholder="Họ tên"
												className="w-100"
												onChange={(e) => {
													this.setState({ relativeName: e.target.value });
												}}
											/>
										</Form.Item>
									</div>
									<div className="col-lg-4 mt-1">
										<label>Điện Thoại</label>
										<Form.Item>
											<Input
												className="w-100"
												value={this.state.relativePhone}
												placeholder="Điện thoại"
												onChange={(e) => {
													if (
														isNaN(e.target.value) ||
														e.target.value.length > 11
													)
														return;
													this.setState({ relativePhone: e.target.value });
													this.validator.showMessages();
												}}
											/>
											{this.validator.message(
												"Số điện thoại",
												this.state.relativePhone,
												"phonenumber"
											)}
										</Form.Item>
									</div>
									<div className="col-lg-4 mt-1">
										<label>Quan hệ</label>
										<Form.Item>
											<Select
												className="w-100"
												value={this.state.relative}
												onChange={(value) => {
													this.setState({ relative: value });
												}}
											>
												<Option key={"cha"} value={"cha"}>
													Cha
												</Option>
												<Option key={"mẹ"} value={"mẹ"}>
													Mẹ
												</Option>
												<Option key={"vợ"} value={"vợ"}>
													Vợ
												</Option>
												<Option key={"chồng"} value={"chồng"}>
													Chồng
												</Option>
												<Option key={"anh/chị"} value={"anh/chị"}>
													Anh/Chị
												</Option>
												<Option key={"khác"} value={"khác"}>
													Khác
												</Option>
											</Select>
										</Form.Item>
									</div>
									<div className="col-12 mt-1">
										<label>Địa chỉ</label>
										<Form.Item>
											<Input
												className="w-100"
												value={this.state.relativeAddress}
												placeholder="Đia chỉ"
												onChange={(e) => {
													this.setState({ relativeAddress: e.target.value });
												}}
											/>
										</Form.Item>
									</div>
								</div>
							</div>
						</div>
						<div
							style={{
								display: `${this.state.madoituong === 1 ? "" : "none"}`,
							}}
							className="tab-pane fade"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div className={`collapse`} id="collapseBHYT">
								<Form ref={(c) => (this.formBHYT = c)}>
									<div className="row">
										<div className="col-lg-4 mt-1">
											<label>Số thẻ BHYT</label>
											<Form.Item name="cardNumber">
												<Input
													value={this.state.cardNumber.toUpperCase()}
													maxLength="15"
													placeholder="Nhập số thẻ"
													onChange={(e) => {
														this.setState({ cardNumber: e.target.value });
														this.validator.showMessageFor("Số thẻ BHYT");
													}}
												/>
												{this.state.madoituong === 1 &&
													this.validator.message(
														"Số thẻ bảo hiểm",
														this.state.cardNumber,
														"required|bhyt"
													)}
											</Form.Item>
										</div>
										<div className="col-lg-4 mt-1">
											<label>Thời hạn thẻ</label>
											<Form.Item name="dateExpired">
												<RangePicker
													locale={viVN}
													value={this.state.dateExpired}
													format={"DD-MM-YYYY"}
													placeholder={["DD-MM-YYYY", "DD-MM-YYYY"]}
													onChange={(date) => {
														date !== null
															? this.setState({ dateExpired: date })
															: this.setState({ dateExpired: [null, null] });
														this.validator.showMessages();
													}}
												/>
												{this.state.madoituong === 1 &&
													this.validator.message(
														"Thời hạn thẻ bảo hiểm",
														this.state.dateExpired
															? this.state.dateExpired[1]
															: [null, null],
														"required|expired"
													)}
											</Form.Item>
										</div>
										<div className="col-lg-4 mt-1">
											<label>BV đăng ký</label>
											<Form.Item name="cardAddress">
												<Autocomplete
													width="100%"
													ref={(c) => (this.autocomplete = c)}
													dataSource={this.state.lstcardAddress}
													handleSearch={this.loadKCB.bind(this)}
													getItem={(key, value) => {
														this.setState({
															cardCode: key,
															cardAddress: key,
															cardAddressLabel: value,
														});
														this.formBHYT.setFieldsValue({ cardCode: key });
													}}
													value={this.state.cardAddress}
												/>
												{this.state.madoituong === 1 &&
													this.validator.message(
														"Bệnh viện đăng ký",
														this.state.cardAddress,
														"required"
													)}
											</Form.Item>
										</div>
										<div className="col-lg-4 mt-1">
											<label>Loại BHYT</label>
											<Form.Item>
												<Select
													className="w-100"
													value={this.state.typeBHYT}
													onChange={(value) => {
														this.setState({ typeBHYT: value });
													}}
												>
													<Option key={1} value={1}>
														{"Đúng tuyến"}
													</Option>
													<Option key={2} value={2}>
														{"Cấp cứu"}
													</Option>
													<Option key={3} value={3}>
														{"Trái tuyến"}
													</Option>
													<Option key={4} value={4}>
														{"Thông tuyến"}
													</Option>
												</Select>
											</Form.Item>
										</div>
									</div>
								</Form>
							</div>
						</div>
						<div
							className="tab-pane fade show"
							id="upload"
							role="tabpanel"
							aria-labelledby="upload-tab"
						>
							<div className={`collapse`} id="uploaddata">
								<Upload
									disabled={isnotEdit === true}
									patientid={this.state.code}
									reload={this.state.isReload}
									isUpload={false}
									resetLoad={(value) => this.setState({ isReload: false })}
									fileList={(fileList) => {
										this.setState({ fileList: fileList });
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderServicesInfo(isnotEdit, columnsSearh) {
		const columnNguoiGioiThieu = [
			{
				title: 'Mã',
				dataIndex: 'code',
				key: 'code',
				width: 80,
			},
			{
				title: 'Tên',
				dataIndex: 'hoten',
				key: 'hoten',
				width: 250,
			},
			{
				title: 'Điện thoại',
				dataIndex: 'dienthoai',
				key: 'dienthoai',
				width: 100,
			},
			{
				title: 'Giới tính',
				dataIndex: 'gioitinh',
				key: 'gioitinh',
				width: 80,
			},
		];

		const columnSale = [
			{
				title: 'Mã',
				dataIndex: 'code',
				key: 'code',
				width: 80,
			},
			{
				title: 'Tên',
				dataIndex: 'hoten',
				key: 'hoten',
				width: 250,
			},
			{
				title: 'Điện thoại',
				dataIndex: 'phone',
				key: 'phone',
				width: 100,
			},
		];

		const columnPackage = [
			{
				title: "Mã Dịch vụ",
				dataIndex: "code",
				key: "code",
				width: 70,
			},
			{
				title: "Tên dịch vụ",
				dataIndex: "name",
				key: "name",
				width: 300,
			},
		];
		let { disabled } = false;
		return (
			<div className="warrperStyleGroup" style={{ height: "100%" }}>
				<div className="grouplabel">
					<h4 style={{ fontWeight: "bold" }}>Thông tin khám bệnh</h4>
				</div>
				<Form ref={(c) => this.form = c} >
					<div className="row">
						<div className="col-lg-12 mt-1">
							<label className={this.props.marketingRequired ? "required-field" : ""}>Kênh khách hàng</label>
							<Form.Item name="marketing" >
								{this.state.lstMarketing && this.state.lstMarketing.map((item, index) => {
									return (
										<Checkbox key={index} onChange={(e) => this.addMarkerting(e)} value={item.id} checked={this.state.marketing.includes(item.id.toString())}>{item.ten}</Checkbox>
									)
								})
								}
							</Form.Item>
						</div>
					</div>
					<div className="row">
					<div className="col-3 mt-1">
						<label className={this.props.coporationRequired ? "required-field" : ""}>Nguồn khách hàng</label>
							<Form.Item name="coporator">
								<Select value={this.state.coporator} disabled={disabled || isnotEdit} placeholder="Chọn nguồn khách hàng"
									onChange={(e) => {
										if (e !== '' && e !== null && e !== undefined) {
											this.setState({ coporator: e });
											this.loadCoporationDetail(e);
										} else {
											this.setState({
												idsale: '',
												idngt: '',
												tenngt : '',
                                                saleName : '',
												banggia: this.state.banggiaTheoSite,
                                                disableCopor : false,
												defaultpayment : null,
												coporator: null,
											})
											if (this.state.banggia !== "default") this.setState({ listSelectedServices : []});
										}
									}}
									showSearch
									allowClear
									onSearch={(value) => this.loadCoporation(value)}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										|| option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}>
									{this.state.listCoporators.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
								</Select>
							</Form.Item>
					</div>
					<div className="col-3 mt-1">
							<>
							<label>Người giới thiệu</label>
							<Form.Item onKeyDown={(e) => this.handleEnter(e, 11)}
								// className={`${disabled === true ? 'input-formitem-disable' : 'input-formitem'}`}
							>
								<AutocompletetableNoGroup
									ref={(c) => this.AutocompletePatient = c}
									placeholder={"Nhập thông tin người giới thiệu"}
									width='100%'
									tablewidth={500}
									onSearch={this.loadNguoiGioiThieu.bind(this)}
									onSelect={this.SelectNguoiGioiThieu.bind(this)}
									headers={columnNguoiGioiThieu}
									keyValue={'id'}
									value={this.state.tenngt}
									showRemove={this.state.tenngt !== '' && this.state.tenngt !== undefined && this.state.tenngt !== null && !this.state.disableCopor && !isnotEdit}
									CleanText={() => {
										this.setState({ idngt: '', tenngt: '' });
									}}
									disabled={this.state.disableCopor || isnotEdit}
								/>
							</Form.Item>
							</>
							
					</div>
					<div className="col-3 mt-1">
						<>
							<label>Sale</label>
							<Form.Item onKeyDown={(e) => this.handleEnter(e, 11)}
								// className={`${disabled === true ? 'input-formitem-disable' : 'input-formitem'}`}
							>
								<AutocompletetableNoGroup
									ref={(c) => this.AutocompletePatient = c}
									placeholder={"Nhập thông tin sale"}
									width='100%'
									tablewidth={500}
									onSearch={this.loadSale.bind(this)}
									onSelect={this.SelectSale.bind(this)}
									headers={columnSale}
									keyValue={'id'}
									value={this.state.saleName}
									showRemove={this.state.saleName !== '' && this.state.saleName !== undefined && this.state.saleName !== null && !this.state.disableCopor && !isnotEdit} 
									CleanText={() => {
										this.setState({ idsale: '', saleName: '' });
									}}
									disabled={this.state.disableCopor || isnotEdit}
								/>
							</Form.Item>
							</>
					</div>
					{/* <div className="col-3 mt-1">
						<label>Bảng giá</label>
						<Form.Item>
						<Select
							label="Bang gia"
							className="w-100"
							showSearch
							allowClear={true}
							value={this.state.banggia}
							disabled={true}
						/>
						</Form.Item>
					</div> */}
					<div className="col-3 mt-1">
						<label>Hình thức thanh toán mặc định</label>
						<Form.Item>
							<Select value={this.state.defaultpayment} disabled={true}>
								<Option key={1} value={1}>Tiền mặt</Option>
								<Option key={2} value={2}>Chuyển khoản</Option>
								<Option key={3} value={3}>Quẹt thẻ</Option>
								<Option key={4} value={4}>Công nợ</Option>
							</Select>
						</Form.Item>
					</div>
					{/* <div className="col-1 mt-2">
						<label></label>
						<Form.Item>
							<Checkbox onChange={(e) =>{this.checkPaylater(e)}}/>
						</Form.Item>
					</div> */}
					<div className="col-12 mt-1 d-flex flex-wrap justify-content-between">
						<div style={{ width: "20%" }}>
							<label>Chiều cao</label>
							<Form.Item>
								<InputNumber className="w-100" placeholder="Cm" value={this.state.chieucao} min={0} maxLength={5}
									onKeyDown={(e) => {
										if (e.key === 'e') {
											e.preventDefault();
										}
									}}
									disabled={isnotEdit}
									onChange={(e) => {
										this.setState({ chieucao: e ? e.toString() : '' })
									}}
								/>
							</Form.Item>
						</div>
						<div style={{ width: "20%" }}>
							<label>Cân nặng</label>
							<Form.Item>
								<InputNumber className="w-100" placeholder="Kg" value={this.state.cannang} min={0} maxLength={5}
									onKeyDown={(e) => {
										if (e.key === 'e') {
											e.preventDefault();
										}
									}}
									disabled={isnotEdit}
									onChange={(e) => {
										this.setState({ cannang: e ? e.toString() : '' })
									}}
								/>
							</Form.Item>
						</div>
						<div style={{ width: "20%" }}>
							<label>Huyết áp</label>
							<Form.Item>
								<Input className="w-100" placeholder="mmHg" value={this.state.huyetap} maxLength={9}
									disabled={isnotEdit}
									onChange={(e) => {
										this.setState({ huyetap: e.target.value ? e.target.value.toString().replace(/[a-zA-Z~!@#$%^&*()_+*.{}|;:,?'"\-\\=]/g, "") : '' })
									}}
								/>
							</Form.Item>
						</div>
						<div style={{ width: "20%" }}>
							<label>Mạch</label>
							<Form.Item>
								<InputNumber className="w-100" placeholder="Lần/phút" value={this.state.mach} min={40} maxLength={5} max={250}
									onKeyDown={(e) => {
										if (e.key === 'e') {
											e.preventDefault();
										}
									}}
									disabled={isnotEdit}
									onChange={(e) => {
										this.setState({ mach: e ? e.toString() : '' })
									}} />
							</Form.Item>
						</div>
						<div style={{ width: "20%" }}>
							<label>Nhiệt độ</label>
							<Form.Item>
								<InputNumber className="w-100" placeholder="°C" value={this.state.nhietdo} min={35} maxLength={5} max={41}
									onKeyDown={(e) => {
										if (e.key === 'e') {
											e.preventDefault();
										}
									}}
									disabled={isnotEdit}
									onChange={(e) => {
										this.setState({ nhietdo: e ? e.toString() : '' })
									}}
								/>
							</Form.Item>
						</div>
					</div>
					<div className="col-12 mt-1 d-flex flex-wrap justify-content-between">
						<div style={{ width: "20%" }}>
							<label>Nhịp thở</label>
							<Form.Item>
								<InputNumber className="w-100" placeholder="Lần/phút" value={this.state.nhiptho} maxLength={5}
									onKeyDown={(e) => {
										if (e.key === 'e') {
											e.preventDefault();
										}
									}}
									disabled={isnotEdit}
									onChange={(e) => {
										this.setState({ nhiptho: e ? e.toString() : '' })
									}}
								/>
							</Form.Item>
						</div>
						<div style={{ width: "20%" }}>
							<label>BMI</label>
							<Form.Item>
								<InputNumber 
									className="w-100" 
									placeholder="BMI"
									disabled={isnotEdit}
									value={this.state.cannang && this.state.chieucao && Number(this.state.cannang/(Math.pow(this.state.chieucao/100,2))).toFixed(2)} 
									readOnly={true}/>
							</Form.Item>
						</div>
						<div style={{ width: "60%" }}>
							<label>Lý do khám</label>
							<Form.Item>
								<Input
									disabled={isnotEdit}
									className="w-100"
									placeholder="Nhập lý do khám"
									value={this.state.reasonCome}
									onChange={(e) => this.setState({ reasonCome: e.target.value })}
								/>
							</Form.Item>
						</div>
					</div>

					<div className="col-lg-3 mt-1 d-none">
						<label>Đối tượng</label>
						<Form.Item>
							<Select
								showSearch
								allowClear={false}
								defaultValue={2}
								value={this.state.madoituong}
								onChange={(value) => {
									this.setState({ madoituong: value });
									if (value !== 1) {
										this.validator.purgeFields();
									}
								}}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
							>
								{this.state.lstSubject.map((row, index) => {
									return (
										<Option key={index} value={row.value}>
											{row.label}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</div>
					<div className="col-lg-3 mt-1 d-none">
						<label>Chuyên khoa</label>
						<Form.Item required={true}>
							<Select
								allowClear={true}
								value={this.state.selectedExam}
								className="w-100"
								onChange={(e) => {
									let selRoom = null;
									for (let temp of this.state.lstRooms) {
										if (temp.mavp === e) {
											selRoom = temp.value.toString();
											break;
										}
									}

									this.setState({ selectedExam: e, selectedRoom: selRoom });
								}}
							>
								{this.state.listServices.map((row, index) => {
									return (
										<Option key={index} value={row.value}>
											{row.label}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</div>
					<div className="col-lg-3 mt-1 d-none">
						<label>Phòng khám</label>
						<Form.Item required={true}>
							<Select
								allowClear={true}
								value={this.state.selectedRoom}
								className="w-100"
								onChange={(e) => {
									this.setState({ selectedRoom: e });
								}}
							>
								{this.state.lstRooms.map((row, index) => {
									return (
										<Option key={index} value={row.value}>
											{row.label}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</div>
					<div className="col-lg-3 mt-1 d-none">
						<label>Số thứ tự</label>
						<div className="h5 font-weight-bold">{this.state.enterNumber}</div>
					</div>
					<div className="col-12 mt-2 d-flex">
						<div className="col-12 mt-2 d-flex">
							<div style={{ display: "flex", flex: 1 }}>
								<Tooltip placement="leftTop" title={`Bảng giá: ${this.state.banggia === 'default' ? "Mặc định" : this.state.banggia}`}>
									<Form.Item label="Chọn dịch vụ">
										<AutocompleteTable
											ref={(c) => (this.listServicesRef = c)}
											width="100%"
											disabled={isnotEdit === true}
											placeholder="Nhập tên dịch vụ để tìm kiếm"
											tablewidth={500}
											listHeight={"50vh"}
											onSearch={(value) => this.searchIndication(value)}
											onSelect={(item) => this.onSelectIndication(item)}
											value={this.state.serKey}
											headers={columnsSearh}
											keyValue={"id"}
										></AutocompleteTable>
									</Form.Item>
								</Tooltip>
								<Form.Item label="">
									<Tooltip title="Mở danh sách dịch vụ">
										<Button className="rounded"
											style={{ marginRight: 10, marginLeft: "3px" }}
											disabled={isnotEdit === true}
											onClick={() => {
												this.setState(
													{
														visibleCLS: true,
													},
													() => {
														this.modalCLS.UpdateSelectedFromProps(
															this.state.listSelectedServices
														);
														this.modalCLS.focusSearchControl();
													}
												);
											}}
										>
											{" "}
											<i
												className={"fas fa-list"}
												style={{ marginRight: 5 }}
											/>{" "}
											F2
										</Button>
									</Tooltip>
									{/* <Tooltip title="Chọn gói khám">
										<Button
											disabled={isnotEdit === true}
											onClick={() => {
												this.setState({
													visiblePackage: true
												}, () => {
													if (this.state.patientID !== null && this.modalPackage)
														this.modalPackage.getPackagePatient(this.state.patientID);
												})
											}}> <i className={"fas fa-box"} /></Button>
                                      </Tooltip> */}
								</Form.Item>
								<Form.Item>
									<Tooltip title="Chọn gói dịch vụ">
										<AutocompleteTable
											width="100%"
											disabled={isnotEdit === true}
											placeholder="Nhập tên gói dịch vụ để tìm kiếm"
											tablewidth={500}
											listHeight={"50vh"}
											onSearch={(value) => this.searchPackage(value)}
											onSelect={(item) => this.onSelectPackage(item)}
											value={this.state.serKey}
											headers={columnPackage}
											keyValue={"id"}
										></AutocompleteTable>
									</Tooltip>
								</Form.Item>
								{(FeatureDeactive().indexOf(FEATURE.CTKM) == -1
									|| FeatureDeactive().indexOf(FEATURE.DEBITCARD) == -1) &&
								<>
									<Button className="btn btn-secondary"
										disabled={isnotEdit === true}
										onClick={() =>
											this.setState({
												visiblePromotion : true,
												detailPatient: this.administrative.getState(),
											})}
									>Mã giảm giá</Button>
									<span className="ml-2">{this.getListVoucherSelected()}</span>
									{this.state.ghichupromo && 
										<Tooltip title={
											<div style={{ 'white-space' : 'pre-wrap'}}>
												Ghi chú: {this.state.ghichupromo}
											</div>
										}>
											<span className='ml-2'><i className="fas fa-info-circle"></i></span>
										</Tooltip>
									}
								</>}
							</div>
							{this.state.listSelectedServices.concat(
								this.state.listSelectedPackage
							).length > 0 && (
									<div style={{ display: "flex", width: 150 }}>
										<div style={{ flex: 1, textAlign: "right" }}>
											Tổng:{" "}
											<strong>{this.state.total.toLocaleString("en-US")}</strong>
										</div>
									</div>
								)}
						</div>
					</div>
					<div className="col-12 mt-2" style={{ height: "50%" }}>
						<div style={{ display: "flex", marginBottom: "8px" }}>
							<div style={{ flex: 1 }}>
								<b>Tên dịch vụ</b>
							</div>
							<div style={{ flex: 1 }}>
								<b>Tên phòng</b>
							</div>
							<div style={{ flex: 1, textAlign: "right" }}>
								<b>Đơn giá</b>
							</div>
							<div style={{ flex: 1, textAlign: "center" }}>
								<b>Số lượng</b>
							</div>
							<div style={{ flex: 1, textAlign: "right" }}>
								<b>Thành tiền</b>
							</div>
							<div style={{ width: 60, textAlign: "center" }}></div>
						</div>
						{this.state.listSelectedServices &&
							this.state.listSelectedServices.length > 0 && (
								<div className={"tableChildHeight"}>
									{this.state.listSelectedServices
										.concat(this.state.listSelectedPackage)
										.map((row) => (
											<Tooltip
												title={
													row.voucher &&
													row.voucher.length > 0 &&
													"Thuộc chương trình loyalty"
												}
											>
												<div style={{ display: "flex", marginBottom: "8px" }}>
													<div style={{ flex: 1 }}>{row.ten}</div>
													<div style={{ flex: 1 }}>
														{row.groupId === 8 &&
															<span><i style={{ marginRight: 5 }} className='fa fa-sync'
																onClick={() => {
																	this.setState({ modalSelectRoom: true, selectedDetail: row })
																}}
															></i> {row.tenphong} </span>
														}
													</div>
													<div style={{ flex: 1, textAlign: "right" }}>
														{row.dongiakm !== null && row.dongiakm !== undefined && row.dongiakm < row.dongia ?
															<span><s>{row.dongia.toLocaleString("en-US")}</s><b>&rarr;{row.dongiakm.toLocaleString("en-US")}</b></span>
															: row.dongia.toLocaleString("en-US")
														}
													</div>
													<div style={{ flex: 1, textAlign: "center" }}>
														{row.soluong}
													</div>
													<div style={{ flex: 1, textAlign: "right" }}>
														{Number(
															(
																(row.dongiakm !== null && row.dongiakm !== undefined && row.dongiakm < row.dongia ? row.dongiakm : row.dongia) *
																row.soluong *
																((100 - row.discount) / 100)
															).toFixed(0)
														).toLocaleString("en-US")}
													</div>
													{row.packageid !== "0" && row.idvp ? (
														<div
															style={{ width: 60, textAlign: "center" }}
														></div>
													) : (
														<div style={{ width: 60, textAlign: "center" }}>
															<button
																disabled={isnotEdit === true}
																style={{ textAlign: "center", marginLeft: 10 }}
																className="btn btn-danger"
																onClick={() => this.XoaChiDinhGoiKham(row)}
															>
																<i className="fas fa-trash" />
															</button>
														</div>
													)}
												</div>
											</Tooltip>
										))}
								</div>
							)}
					</div>
				</div>
			</Form>
			</div>
		);
	}
	handleKeyPress(event) {
		if (event.keyCode === 113) {
			this.setState(
				{
					visibleCLS: true,
				},
				() => {
					this.modalCLS.UpdateSelectedFromProps(
						this.state.listSelectedServices
					);
					this.modalCLS.focusSearchControl();
				}
			);
			event.preventDefault();
		}
	}
	checkVoucher(voucher = this.state.voucher, mabn = this.state.code) {
		voucher && voucher.length > 0
			? PromotionalCampagin.GetServiceVoucher(voucher, mabn)
				.then((res) => {
					if (res.isSuccess) {
						this.onSelectIndication(res.returnObject);
						this.setState({
							ghichupromo : res.returnObject.ghichu,
						})
						notification.success({
							message: "Đã áp dụng thành công mã khuyến mại",
						});
					} else {
						if (res.err.msgString)
							notification.warning({ message: res.err.msgString });
						else
							notification.warning({
								message: "Đã xảy ra lỗi, vui lòng thử lại sau",
							});
					}
				})
				.catch(() => { })
			: notification.warning({ message: "Chưa nhập mã" });
	}

	getListVoucher(mabn = this.state.code) {
		mabn && mabn !== ""
			? PromotionalCampagin.GetListServiceVoucher(mabn)
				.then((res) => {
					if (res.isSuccess) {
						this.setState({
							listServiceVoucher: res.data,
						});
					} else {
						if (res.err.msgString)
							notification.warning({ message: res.err.msgString });
						else
							notification.warning({
								message: "Đã xảy ra lỗi, vui lòng thử lại sau",
							});
					}
				})
				.catch(() => { })
			: notification.warning({ message: "Chưa chọn bệnh nhân" });
	}
	editPhongKham(row, dataPK) {
        let { listSelectedServices } = this.state
        let index = listSelectedServices.findIndex(record => record.id === row.id && record.groupId===8);
        listSelectedServices[index].makp = dataPK.makhoa;
        listSelectedServices[index].maphong = dataPK.maphong;
        listSelectedServices[index].tenphong = dataPK.tenphong;
        this.setState({ listSelectedServices, modalSelectRoom: false })
    }
	
	addNotExistCopr(value, title) {
		let data = this.state.listCoporators;
		let found = data.find(x => x.ma === value);
		if (found === undefined) {
			data.unshift({
				ma: value,
				ten: title,
			});
			this.setState({
				listCoporators: data,
			})
		}
	}
	
    loadMarketing(){
        ConfigService.GetKenhKhachHang(1,100,false).then(result => {
            if(result.isSuccess){
                this.setState({
                    lstMarketing : result.data
                })
            }else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {

        })
    }

    addMarkerting = (e) => {
        let lst = this.state.marketing;
        if(e.target.checked){
            lst.push(e.target.value.toString());
        } else { 
            lst = lst.filter((item) => item !== e.target.value.toString())
        }
        this.setState({ marketing : lst });
    }

	onApplyLoyaltyService(listServices) {
		const { listSelectedServices } = this.state;
		listServices.forEach(service => {
			var existed = listSelectedServices.find((x) => Number(x.id) === Number(service.id));
			if (existed) {
				existed.dongia = service.dongia;
				existed.dongiakm = service.dongiakm ? service.dongiakm : service.dongia;
				existed.voucher = service.voucher;
				existed.isParent = service.isParent ? service.isParent : false;
			} else {
				if (service.groupId === KHAMBENH) {
					PhongKhamService.GetRoomDefault(service.makp || "", service.id).then((respone) => {
						if (respone.isSuccess) {
							service.phongkham = respone.item.makp || "";
							service.tenphong = respone.item.tenkp || "";
							service.maphong = respone.item.makp || "";
						}
					})
				} else {
					service.phongkham = "";
					service.tenphong = "";
					service.maphong = "";
				}
				this.createServiceInfo(service);
			}
		});
		let total = 0;
		listSelectedServices.map(
			(x) => (total += (x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
		);
		this.setState({
			listSelectedServices: listSelectedServices,
			total: total,
		});
	}

	createServiceInfo(service) {
		const { listSelectedServices, banggia } = this.state;
		service.banggia = banggia;
		service.soluong = 1;
		service.idChiDinh = "0";
		listSelectedServices.push(service);
	}

	getListVoucherSelected() {
		let { listSelectedServices } = this.state;
		if (listSelectedServices) {
			let listVoucherFound = [... new Set(listSelectedServices.filter(x => x.voucher !== undefined && x.voucher !== null && x.voucher !== "").map(x => (x.voucher)))];
			let result = "";
			if (listVoucherFound && listVoucherFound.length > 0) result += "Mã đang áp dụng: ";
			return result + listVoucherFound.join(", ");
		}
	}

	removeVoucherService(listToRemove) {
		const { listSelectedServices } = this.state;
		if (listSelectedServices) {
			let newListService = listSelectedServices.filter(x => !listToRemove.includes(x.id));
			let total = 0;
			newListService.map(
				(x) => (total += (x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
			);
			this.setState({
				listSelectedServices: newListService,
				total: total,
			});
		}
	}

	printPID() {
		PrintService.printPID(this.state.code).then(result => {
			if (isNullOrEmpty(result.item.template)) {
				Actions.openMessageDialog("Thông báo", "Không có thông tin để in");
				return;
			} else {
				const template = Handlebars.compile(result.item.template);
				Handlebars.registerHelper(handlebarHelper);
				Actions.DoPrint(template(result.item));
			}
		})
	}

	render() {
		let { isnotEdit } = this.state;
		const columnsSearh = [
			{
				title: "Mã Dịch vụ",
				dataIndex: "mavp",
				key: "mavp",
				width: 70,
			},
			{
				title: "Tên dịch vụ",
				dataIndex: "ten",
				key: "ten",
				width: 300,
			},
			{
				title: "Đơn giá",
				dataIndex: "dongia",
				key: "dongia",
				render: (value) => {
					return <span>{value.toLocaleString("en-US")}</span>;
				},
				width: 70,
				format: "number",
			},
		];

		return (
			<React.Fragment>
				<div
					style={{
						display: "flex",
						justifyContent: "space-around",
						height: "100%",
						width: "100%",
						overflow: "auto",
					}}
					onKeyDown={this.handleKeyPress.bind(this)}
				>
					<div
						style={{
							flex: 5,
							height: "100%",
							width: "100%",
							flexDirection: "column",
							display: "flex",
							overflow: "auto",
						}}
					>
						<div
							style={{
								// minHeight: "50%",
								backgroundColor: "#fff",
								marginRight: 10,
								height: "max-content",
								display: "inline-table",
							}}
							className="containerChilderWrapper"
						>
							{this.renderPatientInfo(isnotEdit)}
						</div>
						<div
							style={{
								minHeight: "55%",
								display: "flex",
								flexDirection: "column",
								margin: "10px 10px 0px 0px",
							}}
							className="containerChilderWrapper"
						>
							<div style={{ flex: 1 }}>
								{this.renderServicesInfo(isnotEdit, columnsSearh)}
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: 10,
								}}
							>
								<button
									className="btn btn-color"
									disabled={this.state.canPrint !== false}
									style={{ marginRight: 10 }}
									onClick={() => {
										this.createNew();
									}}
								>
									{" "}
									<i style={{ marginRight: 5 }} className="fa fa-save" />
									Lưu
								</button>
								<button
									style={{ border: "1px solid #d9d9d9" }}
									className="btn btn-light"
									disabled={this.state.canPrint === false}
									onClick={() => {
										this.PrintRecepRecipt(this.state.maql);
									}}
								>
									<i style={{ marginRight: 5 }} className="fa fa-print" />
									In
								</button>
								<button
									style={{ border: "1px solid#d9d9d9", marginLeft: "10px" }}
									className="btn btn-light"
									onClick={() => {
										this.resetState();
									}}
								>
									{" "}
									<i
										style={{ marginRight: 5 }}
										className="fas fa-sign-out-alt"
									/>
									Kế tiếp
								</button>
								<button
									disabled={isNullOrEmpty(this.state.code)}
									className={`${BUTTON.THEME} ml-2`}
									onClick={() => {
										this.printPID();
									}}	
								>
									In PID
								</button>
							</div>
						</div>
					</div>
					<div style={{ flex: 2 }}>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								position: "absolute",
								top: "8px",
								right: 0,
								border: "solid 1px #d9d9d9",
							}}
						>
							<Button
								className="btnListRegist"
								style={{
									fontWeight: "bold",
									color: "blue",
									cursor: "pointer",
									zIndex: 9,
								}}
								onClick={() => {
									this.setState({
										visibleListRegisted: !this.state.visibleListRegisted,
									});
									if (this.Registerpatient)
										this.Registerpatient.loadListPatient();
								}}
							>
								<i
									className={`${this.state.visibleListRegisted === true
										? "fa fa-caret-left listRegist-icon-close"
										: "fa fa-caret-left listRegist-icon-open"
										}`}
									style={{ marginRight: "3px" }}
								></i>
								{/* {`${this.state.visibleListRegisted === true ? "" : " Danh sách BN"
									}`} */}
							</Button>
						</div>
						<div
							className="containerChilderWrapper"
							style={{ overflowY: "auto" }}
						>
							<Registerpatient
								getRef={(c) => (this.Registerpatient = c)}
							></Registerpatient>
						</div>
					</div>
				</div>
				{this.state.visiblePatient && this.state.patientObject && (
					<EditModal
						reloadListPatient={this.loadListPatient.bind(this)}
						patientObject={this.state.patientObject}
						onCancel={() => {
							this.setState({ visiblePatient: false, patientObject: null });
						}}
						visiblePatient={this.state.visiblePatient}
					></EditModal>
				)}

				<Modal
					maskClosable={false}
					cancelButtonProps={{ style: { display: "none" } }}
					okButtonProps={{ style: { display: "none" } }}
					visible={this.state.visibleCLS}
					width={1500}
				>
					{this.state.visibleCLS && (
						<ModalCLS
							maskClosable={false}
							ref={(c) => (this.modalCLS = c)}
							editAfterRegister={true}
							isnew={true}
							patientID={this.state.patientID}
							getListChiDinh={this.getListChiDinh.bind(this)}
							onClose={(listSelectedServices) => {
								if (listSelectedServices === null) listSelectedServices = [];
								let total = 0;
								this.state.listSelectedPackage
									.concat(listSelectedServices)
									.map(
										(x) =>
										(total +=
											(x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
									);
								this.setState({
									visibleCLS: false,
									listSelectedServices,
									total: total,
								});
							}}
							onReplaceBangGia={(tenBangGia) => {
								if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
									this.setState({
										banggia: tenBangGia,
									});
									this.listServicesRef.handleSearch(this.searchIndication());
								}
							}}
							banggia={this.state.banggia}
						/>
					)}
				</Modal>
				{this.state.visiblePackage && (
					<Modal
						maskClosable={false}
						cancelButtonProps={{ style: { display: "none" } }}
						okButtonProps={{ style: { display: "none" } }}
						visible={this.state.visiblePackage}
						width={1500}
					>
						{this.state.visiblePackage && (
							<ModalPackage
								maskClosable={false}
								ref={(c) => (this.modalPackage = c)}
								patientID={this.state.patientID}
								getListChiDinh={this.getListChiDinh.bind(this)}
								onClose={(listSelectedServices) => {
									if (listSelectedServices === null) listSelectedServices = [];
									let total = 0;
									this.state.listSelectedServices
										.concat(listSelectedServices)
										.map(
											(x) =>
											(total +=
												(x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
										);
									this.setState({
										visiblePackage: false,
										listSelectedPackage: listSelectedServices,
										total: total,
									});
								}}
							/>
						)}
					</Modal>
				)}
				{/* {this.state.modalSelectRoom &&
					<Modal
						maskClosable={false}
						cancelButtonProps={{ style: { display: 'none' } }}
						okButtonProps={{ style: { display: 'none' } }}
						visible={this.state.modalSelectRoom}
						width={1000}>
						<SelectRoom
							item={this.state.selectedDetail}
							onClose={() => { this.setState({ modalSelectRoom: false }) }}
							onSubmit={(value) => {
								this.editPhongKham(this.state.selectedDetail, value)
							}}
						/>
					</Modal>
				} */}
				{this.state.modalSelectRoom &&
					<Modal
						maskClosable={false}
						cancelButtonProps={{ style: { display: 'none' } }}
						okButtonProps={{ style: { display: 'none' } }}
						visible={this.state.modalSelectRoom}
						width={1000}>
						<SelectRoomMulti
							dataSource={this.state.listSelectedServices.map(x => ({ ...x }))}
							onClose={() => { this.setState({ modalSelectRoom: false }) }}
							onSubmit={(values) => {
								this.setState({ listSelectedServices: values })
								this.setState({ modalSelectRoom: false })
							}}
						/>
					</Modal>
				}
				<Modal 
					visible={this.state.visiblePromotion}
					width={'60%'}
				>
					<AppliedVoucher
						type = {LOYALTY_TYPE.SERVICE}
						isView = {true}
						patient={this.state.detailPatient} 
						listSelectedServices={this.state.listSelectedServices} 
						onCancel={() => {this.setState({visiblePromotion : false})}}
						onApply={(listServices) => this.onApplyLoyaltyService(listServices)} 
						bangGia={this.state.banggia}
						removeVoucherService={(listToRemove) => this.removeVoucherService(listToRemove)}
						loyaltyType={LOYALTY_TYPE.VOUCHER}
						/>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
        coporationRequired: state.global.sysConfig.coporationRequired ? state.global.sysConfig.coporationRequired : false,
        marketingRequired: state.global.sysConfig.marketingRequired ? state.global.sysConfig.marketingRequired : false,
        administrativeRequired: state.global.sysConfig.administrativeRequired ? state.global.sysConfig.administrativeRequired : false,
    })
};

export default connect(mapStateToProps)(index)