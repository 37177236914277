import * as ApiCaller from '../libs/httpRequests';
const prefix = 'TuTruc';

export function DanhSachDuocKP() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsduockp`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachCSTT(makp, ngay, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/cosotutruc?makp=${makp}&thang=${ngay}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TaoCoSoTuTruc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/taocosotutruc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CapNhatCoSoTuTruc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updatesoluongtutruc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachPhieuXuatTuTruc(ngay,makp,page,size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsphieuxuattutruc?ngay=${ngay}&makp=${makp}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachLoaiPhieu() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsloaiphieu`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongTinPhieuXuatTuTruc(ngay,id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongtinphieuxuat?ngay=${ngay}&id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachDoiTuong() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsdoituong`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuPhieuXuatTuTruc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuphieuxuattutruc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function CapNhatPhieuXuatTuTruc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updatephieuxuattutruc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XoaThuocTrongPhieu(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/deleteThuoc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachPhieuVayTuTruc(key,ngay,page,size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsphieuvaytutruc?key=${key}&ngay=${ngay}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachKhoVay() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dskhovay`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function LuuPhieuVayTuTruc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuphieuvaytutruc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongTinPhieuVayTuTruc(ngay,id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongtinphieuvay?ngay=${ngay}&id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhatPhieuVayTuTruc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updatephieuvaytutruc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XoaThuocTrongPhieuVay(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/deleteThuocVay`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};