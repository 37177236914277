import { Form, Input, InputNumber, Modal, notification, Select, Statistic, Card, Checkbox, Tooltip } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import DateTimePicker from 'react-datetime-picker';
import EditableTable from '../../../../commons/editableTable/index';
import Autocomplete from '../../../../components/autocomplete';
import * as Actions from '../../../../libs/actions';
import * as MedicineStore from '../../../../services/managerStore';
import * as MedicineService from '../../../../services/medicineService';
import * as PrintService from '../../../../services/printService';
import * as KhoService from '../../../../services/KhoService';
import CreateThuoc from '../../../warehouse/config/material/createComponent/CreateMedicine';
import AutocompletetableNoGroup from '../../../../components/autocompletetable/autocompletetableNoGroup';
import CreateVattu from '../../../medicine/components/createThuocVatTu';
import CreateNCC from '../createNCC';
import './medicine.css';
import { ValidationCustom } from '../../../../commons/validationCustom';
import { convertCompilerOptionsFromJson } from 'typescript';
import * as SystemConfig from "../../../../configure/systemConfig";

const { Option } = Select;
const { TextArea } = Input;
const username = localStorage.getItem('name');
const initialState = {
    makho: null,
    maNCC: '',
    material: '',
    tenhc: '',
    sohoadon: '',
    type: '',
    price: 0,
    losx: '',
    sophieu: '',
    soluong: 1,
    tongtien: 0,
    thue: 0,
    stt: 0,
    ma: 0,
    tenthuoc: '',
    donvi: '',
    dongia: 0,
    hansudung: 0,
    vat: 0,
    sotien: 0,
    giaban: 0,
    giamua: 0,
    sldonggoi: 0,
    tenhang: '',
    tennuoc: '',
    dongiacu: 0,
    giabancu: 0,
    sotk: '',
    id: '',
    sl1: 0,
    sl2: 0,
    tyle: 0,
    cuocvc: 0,
    chaythu: '',
    namsx: moment(),
    st_ggia: 0,
    sotienvat: 0,
    manuoc: 0,
    hidenNCC: false,
    totalBill: 0,
}
export default class Medicine extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstKho: [],
            lstColums: [],
            lstNguon: [],
            lstNhaCungCap: [],
            lstThuoc: [],
            lstInfo: [],
            lstAddThuoc: [],
            lstRemoveThuoc: [],
            lstNuocSX: [],
            maNguon: '',
            disabled: false,
            ngsx: null,
            nghh: null,
            lstMaterial: [],
            modalCreateThuoc: false,
            modalCreateNcc: false,
            disablePrint: true,
            disableSave: true,
            ngaynhap: new Date(),
            idPhieu: null,
            listWarehouse: [],
            ghichu: '',
            disableCreate : false,
            inViewMode : false,
        };
    }
    setData(data, status) {
        let lstNhaCungCap = [];
        let totalBill = 0;
        for (let item of data.lstAddThuoc) {
            item.tongtien = (item.soluong * item.dongia);
            totalBill += item.tongtien
        }
        lstNhaCungCap.push({
            value: data.hoadonnhap.manhacc,
            label: data.hoadonnhap.tenncc
        })
        let ngayNhapKho = null;
        if (data.hoadonnhap.ngaynhap != null) {
            ngayNhapKho = moment(data.hoadonnhap.ngaynhap).toDate();
        }
        this.setState({
            totalBill,
            lstNhaCungCap,
            idPhieu: data.idPhieu,
            sophieu: data.hoadonnhap.sophieu,
            sohoadon: data.hoadonnhap.sohoadon,
            manguon: 11,
            makho: data.hoadonnhap.makho,
            maNCC: data.hoadonnhap.manhacc,
            lstAddThuoc: data.lstAddThuoc,
            ngaynhap: ngayNhapKho,
            visible: true,
            disablePrint: false,
            disableSave: true,
            ghichu : data.hoadonnhap.lydo,
            disableCreate : true,
            stt : data.lstAddThuoc[data.lstAddThuoc.length-1].stt,
            inViewMode : status,
        })
        this.newTable.setDataSource(data.lstAddThuoc)
        this.form.setFieldsValue({
            idPhieu: data.idPhieu,
            sophieu: data.hoadonnhap.sophieu,
            sohoadon: data.hoadonnhap.sohoadon,
            manguon: 11,
            makho: data.hoadonnhap.makho,
            lydo : data.hoadonnhap.lydo,
            ngaynhap : ngayNhapKho,
        });
    }

    setNewState() {
        if (this.newTable)
            this.newTable.setDataSource([])

        this.reset()
        this.setState({ disablePrint: true, disableSave: true });
    }
    loadKho() {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess) {
                this.setState({
                    listWarehouse: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }
    componentDidMount() {
        this.loadKho();
        this.loadNhaCungCap();
        this.form.setFieldsValue({
            id: '',
            dongia: 0,
            thue: 0,
            ngsx: null,
            nghh: null,
            ngaynhap: this.state.ngaynhap
        });
    }
    PrintPhieuNhap() {
        let { idPhieu, ngaynhap, tenkho } = this.state
        PrintService.PrintPhieuNhapKho(idPhieu, moment(ngaynhap).format('YYYY-MM-DD'), 1, tenkho).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    loadNguon() {
        MedicineService.GetListNguon().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNguon: result.data,
                    maNguon: result.data[0].value
                });
                this.form.setFieldsValue({ maNguon: result.data[0].value })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nguồn bị lỗi' })
        })
    }
    loadNuocSanXuat(mabd) {
        let item = this.state.lstMaterial.find(x => x.value === Number(mabd));
        if (item) {
            this.setState({ donvi: item.donvi });
        }
        MedicineService.DanhSachNuocSanXuat(mabd).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstNuocSX: result.data,
                    manuoc: result.data[0].value
                });
                this.form.setFieldsValue({
                    manuoc: result.data[0].value
                })
            }
            else {
                this.setState({
                    lstNuocSX: [],
                    manuoc: 0
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nước sản xuất bị lỗi' })
        })
    }
    loadNhaCungCap(key) {
        MedicineService.GetListNhaCungCap(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhaCungCap: result.data,
                });
            }
        }).catch(err => {
        })
    }
    loadMaterial(key) {
        return MedicineService.GetListMaterial(key, this.props.compprops)
    }
    loadPrice(key) {
        MedicineService.GetPriceByMaterialID(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    price: result.item.dongia
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu giá thuốc bị lỗi' })
        })
    }
    resetField() {
        this.setState({
            id: '',
            soluong: 1,
            dongia: 0,
            thue: 0,
            hansudung: 0,
            losx: '',
            ngsx: null,
            nghh: null,
            lstNuocSX: [],
            makho: this.state.makho,
            donvi: '',
        })
        this.form.setFieldsValue({
            dongia: 0,
            thue: 0,
            hansudung: 0,
            losx: '',
            ngsx: null,
            nghh: null,
            manuoc: null,
            donvi: '',
        });
    }

    reset() {
        this.setState({
            ...initialState,
            disabled: false,
            lstAddThuoc: [],
            //     lstNuocSX: [],
            //    lstThuoc: [],
            id: '',
            soluong: 1,
            dongia: 0,
            thue: 0,
            losx: '',
            ngsx: null,
            nghh: null,
            ngay: moment().from("YYYY-MM-DD"),
            idPhieu: null,
            ngaynhap: new Date(),
            ghichu:'',
            disableCreate : false,
            maNCC : null,
            inViewMode : false
        })
        this.form.setFieldsValue({
            id: '',
            manuoc: '',
            sohoadon: '',
            losx: '',
            makho: '',
            hansudung: '',
            ngaynhap: new Date(),
            nghh: null,
            ngsx: null,
            dongia: 0,
            lydo:'',
        })
        if (this.newTable) {
            this.newTable.setDataSource([])
        }
    }
    async AddThuoc() {
        if (this.state.soluong * this.state.dongia + (this.state.soluong * this.state.dongia * this.state.thue / 100) > 1000000000) {
            notification.warning({ message: 'Tổng số tiền nhập vượt mức qui định. Tối đa 10 số!' });
            return;
        }
        if (this.state.soluong <= 0) {
            notification.warning({ message: 'Số lượng phải lớn hơn 0!' });
            return;
        }
        if (this.state.dongia < 0) {
            notification.warning({ message: 'Giá nhập không được nhỏ hơn 0!' });
            return;
        }
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        
        if (this.state.id !== 0) {
            if (numberError === 0 && this.validator.allValid()) {
                MedicineStore.getDetails(this.state.id).then(result => {
                    if (result.isSuccess) {
                        let lst = this.state.lstAddThuoc;
                        let hansudung = 0;
                        if (this.state.nghh != null && this.state.ngsx != null) {
                            hansudung = moment(this.state.nghh, 'DD-MM-YYYY').diff(moment(this.state.ngsx, 'DD-MM-YYYY'), 'days');
                        }
                        let totalBill = this.state.totalBill;
                        let stt = Number(this.state.stt);
                        totalBill += Number(this.state.dongia) * Number(this.state.soluong)
                        lst.unshift({
                            id: Number(this.state.id),
                            key: this.state.id,
                            ma: result.item.ma,
                            soluong: Number(this.state.soluong),
                            dongia: Number(this.state.dongia),
                            thue: Number(this.state.thue),
                            hansudung: hansudung,
                            tongtien: (this.state.soluong * this.state.dongia) + ((this.state.soluong * this.state.dongia * this.state.thue) / 100),
                            donvi: result.item.donvi,
                            ten: result.item.ten,
                            giabancu: result.item.giaban,
                            giamuacu: result.item.dongia,
                            tenhc: result.item.tenhc,
                            tenhang: result.item.tenhang,
                            tennuoc: result.item.tennuoc,
                            manhom: result.item.manhom,
                            sotk: result.item.sotk,
                            sldonggoi: result.item.sldonggoi,
                            losx: this.state.losx,
                            ngsx: this.state.ngsx !== null ? this.state.ngsx : null,
                            nghh: this.state.nghh !== null ? this.state.nghh : null,
                            stt : stt+1,
                        })
                        this.newTable.setDataSource(lst);
                        this.setState({
                            totalBill,
                            disabled: true,
                            hidenNCC: true,
                            lstAddThuoc: lst.map(d => ({ ...d })),
                            stt: stt+1,
                        });
                    }
                    this.resetField();
                }).catch(err => {
                    notification.warning({ message: 'Thêm thuốc bị lỗi. Vui lòng nhập lại!' })
                })
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        }
    }

    submit() {
        if (this.state.lstAddThuoc.length === 0) {
            notification.warning({ message: 'Không có thuốc nào trong danh sách!' });
            return;
        }
        if (this.state.makho === 0) {
            notification.warning({ message: 'Không tìm thấy mã kho!' });
            return;
        }
        let errQuantity = this.state.lstAddThuoc.find(x => x.soluong <= 0);
        this.state.lstAddThuoc.map(x => {
            let quantity_array = x.soluong.toString().split('.');
            if(quantity_array.length > 1 && quantity_array[1].length > 2){
                errQuantity = x
            }
        })
        if (errQuantity) {
            notification.warning({ message: `Số lượng không hợp lệ: ${errQuantity.ten}` });
            return;
        }
        const hoadonnhap = {
            'idPhieu': this.state.idPhieu,
            'sophieu': this.state.sophieu,
            'sohoadon': this.state.sohoadon,
            'losx': this.state.losx,
            'manguon': 11,
            'makho': Number(this.state.makho),
            'manhacc': Number(this.state.maNCC),
            'ngaynhap': this.state.ngaynhap,
            'manhom': this.props.compprops === 1 ? 1 : 2,
            'lydo' : this.state.ghichu,
        }
        const data = {
            "hoadonnhap": hoadonnhap,
            'lstAddThuoc': this.state.lstAddThuoc,
        }
        if (this.state.idPhieu === null) {
            if (this.newTable.validator.allValid()) {
                Actions.setLoading(true);
                MedicineService.NhapKho(data).then(result => {
                    if (result.isSuccess) {
                        this.setState({ idPhieu: result.id, ngay: moment().format("YYYY-MM-DD") })
                        notification.success({ message: 'Lưu thành công!' });
                        this.loadNguon();
                        this.loadNhaCungCap();
                        this.setState({ disablePrint: false });
                        this.props.onCancel()
                        Actions.setLoading(false);
                    }
                    else {
                        this.setState({
                            disabled: false,
                        })
                        Actions.setLoading(false);
                        Actions.openMessageDialog("Lỗi", result.err.msgString);
                    }
                }).catch(err => {
                })
            } else {
                Actions.setLoading(false);
                this.newTable.validator.showMessages();
            }
        } else {
            Actions.setLoading(true);
            MedicineService.editNhapKho(this.state.idPhieu, data).then(result => {
                if (result.isSuccess) {
                    this.setState({ idPhieu: result.id, ngay: moment().format("YYYY-MM-DD") })
                    notification.success({ message: 'Lưu thành công!' });
                    this.loadNguon();
                    this.loadNhaCungCap();
                    this.setState({ disablePrint: false, disabled: false });
                    this.props.onCancel()
                    Actions.setLoading(false);
                }
                else {
                    this.setState({
                        disabled: false,
                    })
                    Actions.setLoading(false);
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
            }).catch(err => {
                Actions.setLoading(false);
            })
        }
    }

    updateLstRemove = (e, record) => {
        let lst = this.state.lstRemoveThuoc;
        if(record.id !==0 ){
            if(e.target.checked)
            {
                lst.push(record);
            } else {
                lst.map((x) => {
                    if(x.id !== record.id ){
                        lst = lst.filter(x => x.id !== record.id)
                    } else {
                        lst = lst.filter(x => x.stt !== record.stt)
                    }
                })
            }
            this.setState({lstRemoveThuoc : lst})
        }
    }

    XoaThuoc() {
        let {lstRemoveThuoc,lstAddThuoc} = this.state;
        if (lstRemoveThuoc.length > 0) {
            let lst = lstAddThuoc;
            lstRemoveThuoc.forEach(element => {
                lst = lst.filter(x => x.stt !== element.stt)
            });
            let totalBill = this.state.totalBill;
            if (lst.length === 0) {
                this.resetField();
                totalBill = 0;
            } else {
                let items = this.state.lstAddThuoc.filter(x => !lstRemoveThuoc.includes(x));
                if (items.length > 0) {
                    totalBill -= (Number(items[0].dongia) * Number(items[0].soluong))
                }
            }

            this.newTable.setDataSource(lst.map(d => ({ ...d })));
            this.setState({ totalBill, lstAddThuoc: lst.map(d => ({ ...d })) })
        }
    }


    handleChangeNewData(data) {
        try {
            data.forEach(item => {
                if (item.ngsx === "") item.ngsx = null;
                if (item.nghh === "") item.nghh = null;
            });
            let totalBill = 0;
            for (let item of data) {
                item.key = item.stt + item.id + item.losx;
                item.tongtien = item.soluong * item.dongia
                totalBill += item.tongtien;
            }
            this.newTable.setDataSource(data);
            this.setState({ totalBill, lstAddThuoc: data });
        } catch (error) {

        }
    }
    render() {
        var columns
        if (this.props.compprops === 1) {
            columns = [
                {
                    title:'#',
                    width: 60,
                    align: 'Center',
                    render: (text, record, index) => (index + 1)
                },
                {
                    title: '',
                    dataIndex: '',
                    key: '',
                    width: 60,
                    align: 'Center',
                    render:(record) => {
                        return <Checkbox checked={this.state.lstRemoveThuoc && this.state.lstRemoveThuoc.filter(x => x.id === record.id && x.stt === record.stt).length > 0} onChange={(e) => {this.updateLstRemove(e, record)}}></Checkbox>
                    }
                },
                {
                    title: 'Mã thuốc/vật tư',
                    dataIndex: 'ma',
                    key: 'ma',
                    width: 150
                },
                {
                    title: 'Tên thuốc/vật tư',
                    dataIndex: 'ten',
                    key: 'ten',
                    width: 250
                },
                {
                    title: 'Hoạt chất',
                    dataIndex: 'tenhc',
                    key: 'tenhc',
                    width: 250
                },
                {
                    title: 'ĐVT',
                    dataIndex: 'donvi',
                    key: 'donvi',
                    width: 60,
                },
                {
                    title: 'Nước sản xuất',
                    dataIndex: 'tennuoc',
                    key: 'tennuoc',
                    width: 120,
                },
                {
                    title: 'Số lượng',
                    dataIndex: 'soluong',
                    key: 'soluong',
                    type: 'number',
                    width: 120,
                    insideWidth: 100,
                    align: 'center',
                    editable: !this.state.inViewMode,
                    isrequire: true,
                    maxLength: 7,
                    min: 0,
                    render: (value) => {
                        return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
                    },
                },
                {
                    title: 'Giá nhập',
                    dataIndex: 'dongia',
                    key: 'dongia',
                    type: 'number',
                    width: 120,
                    insideWidth: 100,
                    align: 'center',
                    editable: !this.state.inViewMode,
                    isrequire: true,
                    maxLength: 9,
                    min: 0,
                    render: (value) => {
                        return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
                    },
                },
                {
                    title: 'Lô sản xuất',
                    dataIndex: 'losx',
                    key: 'losx',
                    editable: !this.state.inViewMode,
                    width: 150,
                    insideWidth: '12%',
                },
                {
                    title: 'Ngày sản xuất',
                    type: "date",
                    dataIndex: 'ngsx',
                    key: 'ngsx',
                    width: 150,
                    insideWidth: 120,
                    editable: !this.state.inViewMode,
                    render: (value) => {
                        return <span>{value !== null ? moment(value).format("DD-MM-YYYY") : 'Không xác định'}</span>;
                    },
                },
                {
                    title: 'Ngày hết hạn',
                    dataIndex: 'nghh',
                    type: "date",
                    key: 'nghh',
                    width: 150,
                    checkDate: true,
                    startDay: '',
                    insideWidth: 120,
                    editable: !this.state.inViewMode,
                    render: (value) => {
                        return <span>{value !== null ? moment(value).format("DD-MM-YYYY") : 'Không xác định'}</span>
                    },
                },
                {
                    title: 'Tổng tiền',
                    dataIndex: 'tongtien',
                    key: 'tongtien',
                    align: 'right',
                    width: 150,
                    render: (value) => {
                        return <span>{value.toLocaleString('en-US')}</span>;
                    },
                },
            ]
        }
        else {
            columns = [
                {
                    title: '',
                    dataIndex: '',
                    key: '',
                    width: 50,
                    render:() => {
                        return <Checkbox></Checkbox>
                    }
                },
                {
                    title: 'Tên Vật tư',
                    dataIndex: 'ten',
                    key: 'ten',
                    width: 180
                },
                {
                    title: 'ĐVT',
                    dataIndex: 'donvi',
                    key: 'donvi',
                    width: 80,
                },
                {
                    title: 'Nước sản xuất',
                    dataIndex: 'tennuoc',
                    key: 'tennuoc',
                    width: 130,
                },
                {
                    title: 'Số lượng',
                    dataIndex: 'soluong',
                    key: 'soluong',
                    type: 'number',
                    width: 80,
                    insideWidth: 70,
                    align: 'right',
                    editable: !this.state.inViewMode,
                    isrequire: true,
                    maxLength: 9,
                    min: 0,
                    render: (value) => {
                        return <span>{value.toLocaleString('en-US')}</span>;
                    },
                },
                {
                    title: 'Giá nhập',
                    dataIndex: 'dongia',
                    key: 'dongia',
                    type: 'number',
                    width: 100,
                    insideWidth: 100,
                    align: 'right',
                    editable: !this.state.inViewMode,
                    isrequire: true,
                    maxLength: 9,
                    min: 0,
                    render: (value) => {
                        return <span>{value.toLocaleString('en-US')}</span>;
                    },
                },
                {
                    title: 'Lô sản xuất',
                    dataIndex: 'losx',
                    key: 'losx',
                    editable: !this.state.inViewMode,
                    width: 120,
                    insideWidth: 100,
                },
                {
                    title: 'Ngày sản xuất',
                    type: "date",
                    dataIndex: 'ngsx',
                    key: 'ngsx',
                    width: 160,
                    insideWidth: 155,
                    editable: true,
                    render: (value) => {
                        return <span>{(value !== "" && value != null && moment(value).year() !== 1) ? moment(value).format("YYYY-MM-DD") : null}</span>;
                    },
                },
                {
                    title: 'Ngày hết hạn',
                    dataIndex: 'nghh',
                    type: "date",
                    key: 'nghh',
                    width: 160,
                    insideWidth: 155,
                    editable: true,
                    render: (value) => {
                        return <span>{(value !== "" && value != null && moment(value).year() !== 1) ? moment(value).format("YYYY-MM-DD") : null}</span>;
                    },
                },
                {
                    title: 'Tổng tiền',
                    dataIndex: 'tongtien',
                    key: 'tongtien',
                    width: 100,
                    align: 'right',
                    render: (value) => {
                        return <span>{value.toLocaleString('en-US')}</span>;
                    },
                },
            ];
        }

        const columnsThuocVatTu = [
            {
                title: 'Tên thuốc - vật tư',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
                width: 300
            },
            {
                title: 'Mã thuốc',
                dataIndex: 'ma',
                key: 'ma',
                align: 'left',
                width: 100
            },
            {
                title: 'Hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                align: 'left',
                width: 100
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',
                width: 80
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                align: 'left',
                width: 120
            }
        ]

        return (
            <>
                <div>
                    <Form.Item disabled={true} label="Nguồn:" name="maNguon" style={{ flex: 1, display: 'none' }}
                        rules={[{ required: true, message: 'Yêu cầu chọn nguồn!' }]}>
                        <Select
                            disabled={this.state.disabled}
                            showSearch
                            onChange={(value) => { this.setState({ maNguon: value }) }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {this.state.lstNguon.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form ref={(c) => this.form = c} style={{ height: 'auto' }} >
                        <div>
                            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>{!this.state.inViewMode ? (this.state.idPhieu === null ? "Tạo phiếu nhập kho" : "Cập nhật phiếu nhập kho") : "Xem phiếu nhập kho"}</h3>
                                <h3 onClick={() => {
                                    if (this.state.disableSelectStore || this.state.idPhieu != null) {
                                    this.reset()
                                    }
                                    this.props.onCancel()
                                }}><i className='far fa-times-circle' /></h3>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mt-2">
                                    <label>Mã phiếu nhập</label>
                                    <Form.Item>
                                        <Input placeholder='Tạo tự động từ hệ thống' readOnly style={{ cursor: 'not-allowed' }} value={this.state.sophieu}></Input>
                                    </Form.Item>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Người nhập </label>
                                    <Form.Item>
                                        <Input placeholder='Người xuất' value={username} readOnly style={{ cursor: 'not-allowed' }}></Input>
                                    </Form.Item>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label className='required-field'>Ngày nhập </label>
                                    <Form.Item>
                                        <DateTimePicker
                                            className="w-100"
                                            locale='vi'
                                            format={'dd-MM-yyyy'}
                                            isClockOpen={false}
                                            isCalendarOpen={false}
                                            calendarIcon={<Tooltip title="Mở lịch"><i className='far fa-calendar-alt customclearicon' /></Tooltip>}
                                            clearIcon={null}
                                            onChange={(e) => this.setState( {ngaynhap: e })}
                                            maxDate={moment().toDate()}
                                            value={moment(this.state.ngaynhap).toDate()}
                                            disabled = {this.state.inViewMode} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mt-2">
                                    <label className='required-field'>Kho</label>
                                    <Form.Item name="makho">
                                        <Select value={this.state.makho} disabled={this.state.disabled || this.state.idPhieu !== null || this.state.inViewMode}
                                            showSearch onChange={(e) => { this.setState({ makho: e }) }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {this.state.listWarehouse &&
                                                this.state.listWarehouse.map((record, index) => {
                                                    return (
                                                        <Option key={index} value={record.value}>{record.label}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    {this.validator.message("Kho", this.state.makho, "selection")}
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Hóa đơn </label>
                                    <Form.Item>
                                        <Input
                                            placeholder="Nhập hóa đơn"
                                            onChange={(e) => { this.setState({ sohoadon: e.target.value }) }}
                                            value={this.state.sohoadon}
                                            disabled = {this.state.inViewMode}
                                        />
                                    </Form.Item>
                                </div> 
                                <div className="col-md-4 mt-2">
                                    <label className='required-field'>Nhà cung cấp </label>
                                    <Form.Item rules={[{ required: true, message: 'Yêu cầu chọn nhà cung cấp!' }]}>
                                        <Select value={this.state.maNCC} disabled={this.state.disabled || this.state.idPhieu !== null || this.state.inViewMode}
                                            style={{width: !this.state.disableCreate && '90%'}}
                                            showSearch onChange={(e) => { this.setState({ maNCC: e }) }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {this.state.lstNhaCungCap &&
                                                this.state.lstNhaCungCap.map((record, index) => {
                                                    return (
                                                        <Option key={index} value={record.value}>{record.label}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        {
                                            !this.state.disableCreate && 
                                            <button className="btn btn-success btn-sm btn-circle ml-1 btn-scale" onClick={() => {
                                            this.setState({ modalCreateNcc: true })}}  disabled={this.state.disableCreate}
                                            ><i className="fa fa-plus" ></i></button>
                                        }
                                    </Form.Item>
                                    {this.validator.message("Nhà cung cấp", this.state.maNCC, "selection")}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-2">
                                    <label>Lý do nhập</label>
                                    <Form.Item name="lydo">
                                        <TextArea rows={3}
                                            placeholder="Nhập lý do"
                                            value={this.state.ghichu}
                                            onChange={(e => { this.setState({ ghichu: e.target.value }); })}
                                            disabled = {this.state.inViewMode}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                            <h5>Chi tiết phiếu nhập</h5>
                        </div>
                        {!this.state.inViewMode && 
                        <>
                            <div className="row">
                                <div className="col-md-12 mt-2 d-flex" >
                                    <div className="w-100" style={{display : 'flex' , align : 'center'}}>
                                        <div className="mt-2" style={{flex : 1}}>
                                            <Form.Item
                                            rules={[{ required: true, message: 'Yêu cầu chọn vật phẩm!' }]}>
                                                <AutocompletetableNoGroup
                                                    ref={(c) => this.searchThuocVatTu = c}
                                                    placeholder="Tìm kiếm theo tên, mã thuốc - vật tư, hoạt chất"
                                                    width='100%'
                                                    onSearch={this.loadMaterial.bind(this)}
                                                    onSelect={(key) => {
                                                        this.setState({ id: key.id }); 
                                                        this.loadNuocSanXuat(key.id);
                                                        this.AddThuoc();
                                                    }}
                                                    headers={columnsThuocVatTu}
                                                    keyValue={'ma'}
                                                    title=""
                                                />
                                        </Form.Item>
                                        </div>
                                        <div>
                                            <button className="btn btn-success btn-sm btn-circle btn-scale" onClick={() => {
                                                this.setState({ modalCreateThuoc: true })
                                                }} ><i className="fa fa-plus" ></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mt-2">
                                    <Form.Item>
                                        <button className="btn btn-danger" onClick={() => {
                                            this.XoaThuoc()
                                        }}> <i style={{ marginRight: 5 }} className="fa fa-trash-alt" />Xóa hạng mục</button>
                                    </Form.Item>
                                </div>
                            </div>
                        </>
                        }
                        <div className="row">
                            <div className="col-md-12 mt-2">
                                <EditableTable
                                    height={'300px'}
                                    ref={(c) => this.newTable = c}
                                    onchange={(data) => { this.handleChangeNewData(data) }}
                                    scrollY={'45vh'}
                                    dataSource={this.state.lstAddThuoc} columns={columns} pagination={false}
                                    keyFunction={this.keyFunction}
                                    rowClassName={(record) => {
                                        return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                                    }}
                                    keyFind={'ma'}
                                    keyFind2={'stt'}
                                ></EditableTable>
                            </div>
                        </div>
                    </Form> 
                    <div className='custom-hr'></div>
                    <div className='row'>
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button className="btn btn-color" onClick={() => { this.PrintPhieuNhap() }}>
                                <i className="fa fa-print mr-2" />In
                            </button>
                            <div>
                                {this.state.idPhieu === null &&
                                    <button onClick={() => { this.reset(); this.form.setFieldsValue({ makho: null }); this.setState({ makho: null }) }} className="btn btn-outline-secondary" style={{ margin: "0px 15px 0px 0px" }}>
                                        <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                                }
                                {!this.state.inViewMode && 
                                    <button className="btn btn-color" onClick={() => { this.submit()  }}>
                                        <i className="fa fa-save mr-2" />Lưu
                                    </button>
                                }
                                <button className="btn btn-danger ml-2" onClick={() => { 
                                    if (this.state.disableSelectStore || this.state.idPhieu != null) {
                                        this.reset()
                                        this.loadNhaCungCap()
                                    }
                                    this.props.onCancel()
                                    }}>
                                    <i className="fas fa-sign-out-alt mr-2" />Đóng
                                </button>
                            </div>
                        </div>
                    </div>       
                </div>
                <Modal maskClosable={false}
                    width={1000}
                    visible={this.state.modalCreateNcc}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateNCC 
                        onCancel={() => { this.setState({ modalCreateNcc: false }) }}
                        onSubmit={(item) => {
                            let { lstNhaCungCap } = this.state;
                            lstNhaCungCap.push(item);
                            this.setState({ maNCC: item.value, lstNhaCungCap });
                            this.form.setFieldsValue({ maNCC: item.value })
                        }}
                    />
                </Modal>
                {this.state.modalCreateThuoc && <Modal maskClosable={false}
                    width={600}
                    visible={this.state.modalCreateThuoc}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateThuoc onCancel={() => { this.setState({ modalCreateThuoc: false }) }}/> 
                </Modal>
                }
            </ >
        )
    }
}


